import { FC } from 'react';

const TextLogo: FC<{
	className?: string;
}> = ({ className }) => (
	<h1
		className={
			'self-center relative text-4xl md:text-6xl lg:text-5xl font-semibold ' +
			className
		}>
		<span className='text-primary font-poppins'>wonder</span>
		<span className='text-primary-4 font-poppins'>Learn</span>
	</h1>
);

export default TextLogo;
