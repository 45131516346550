import type ScrollSnapSlider from './ScrollSnapSlider';

export default class ScrollSnapPlugin {
	get id(): string {
		return this.constructor.name;
	}

	enable(slider: ScrollSnapSlider) {
		console.error(
			`${this.id}: implementation of enable() method missing.`,
			slider
		);
	}

	disable() {
		console.error(`${this.id}: implementation of disable() method missing.`);
	}
}
