import PATH_CONSTANTS from 'assets/Data/path-constants';
import { useRTKSelector } from 'assets/Hooks/redux-hooks';
import { Route, Routes } from 'react-router-dom';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import UnauthenticatedRoutes from './UnauthenticatedRoutes';
import ResetPassword from 'pages/Auth/ResetPassword/ResetPassword';
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy';
import TermsService from 'pages/TermsService/TermsService';

const Router = () => {
	const isAuthenticated = useRTKSelector(
		state => state.userReducer.isAuthenticated
	);

	return (
		<>
			<Routes key='global-routes'>
				<Route
					path={PATH_CONSTANTS.RESET_PASSWORD}
					element={<ResetPassword />}
				/>
				<Route
					path={PATH_CONSTANTS.PRIVACY_POLICY}
					element={<PrivacyPolicy />}
				/>
				<Route
					path={PATH_CONSTANTS.TERMS_OF_SERVICE}
					element={<TermsService />}
				/>
			</Routes>
			{isAuthenticated ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />}
		</>
	);
};

export default Router;
