import { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ScrollSnapSlider from './ScrollSnapSlider';
import SliderCard from './SliderCard';
import ScrollSnapDraggable from './SliderDraggable';
import { themeColors } from 'assets/Data/utils';

const Slider: FC<SliderProps> = ({ heading, data, index }) => {
	const snapRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!snapRef.current) return;
		const slider = new ScrollSnapSlider(snapRef.current);

		slider.roundingMethod = function (x) {
			const direction = x <= slider.slide ? -1 : 1;

			if (direction < 0) return Math.floor(x);

			return Math.ceil(x);
		};

		const draggablePlugin = new ScrollSnapDraggable(0);

		draggablePlugin.enable(slider);
		slider.plugins.set(draggablePlugin.id, draggablePlugin);
	}, []);

	return (
		<SliderWrapper
			{...getColors(index)}
			className={`w-full relative flex justify-start items-center rounded-md shadow-themeShadow ml-7 lg:ml-16 min-h-[9rem] lg:min-h-[10.5rem]`}>
			<div className='relative w-fit z-10 p-6 lg:p-7 lg:pr-0 pr-0 flex flex-col gap-4 lg:gap-5 overflow-hidden'>
				<h3 className='font-fredoka font-medium text-lg lg:text-2xl text-black-4'>
					{heading}
				</h3>
				<div
					ref={snapRef}
					className='flex gap-4 lg:gap-8 cursor-grab snap-scroll-container snap-x scroll-smooth overflow-x-scroll snap-mandatory'>
					{data.map(sliderData => (
						<SliderCard
							category={heading}
							key={sliderData.id}
							{...sliderData}
						/>
					))}
				</div>
			</div>
		</SliderWrapper>
	);
};

const getColors = (index: number) => themeColors[index % themeColors.length];

const SliderWrapper = styled.div<{ colorTop: string; colorBottom: string }>`
	background: linear-gradient(
		180deg,
		${({ colorTop }) => colorTop} 0%,
		${({ colorBottom }) => colorBottom} 100%
	);
`;

export default Slider;

interface SliderProps {
	heading: string;
	data: SliderData[];
	index: number;
}
