enum PATH_CONSTANTS {
	DEFAULT = '/',
	DASHBOARD = '/dashboard',
	RESET_PASSWORD = '/reset-password',
	LIBRARY = '/library',
	PARENTS_COMMUNITY_GROUP = 'https://chat.whatsapp.com/EaDYNBLF40K3Bhoi3PWcfW',
	INVITE = '/invite',
	APP_DOWNLOAD_LINK = 'https://wonderlearn.app.link/app',
	SUPPORT = 'https://wa.me/+918951140320',
	PRIVACY_POLICY = '/privacy-policy',
	TERMS_OF_SERVICE = '/terms-of-service',
	COURSE_PLAYER = '/course-player',
	ALL = '*',
	REPORTS = '/reports',
	REPORT_DETAIL = '/reports/:id'
}

// name for * path

export default PATH_CONSTANTS;
