import type ScrollSnapPlugin from './ScrollSnapPlugin';

export default class ScrollSnapSlider {
	element: HTMLElement;
	slideScrollLeft: number;
	scrollTimeoutId: null | number;
	roundingMethod: (x: number) => number;
	slide: number;
	scrollTimeout: number;
	listenerOptions: AddEventListenerOptions;
	addEventListener: (
		type: string,
		listener: (event: MouseEvent) => void,
		options?: boolean | AddEventListenerOptions
	) => void;
	removeEventListener: (
		type: string,
		listener: (event: MouseEvent) => void,
		options?: boolean | EventListenerOptions
	) => void;
	plugins: Map<string, ScrollSnapPlugin>;

	constructor(
		element: HTMLElement,
		enabled: boolean = true,
		plugins: ScrollSnapPlugin[] = []
	) {
		this.element = element;
		this.slideScrollLeft = this.element.scrollLeft;
		this.scrollTimeoutId = null;
		this.roundingMethod = Math.round;
		this.slide = this.calculateSlide();
		this.scrollTimeout = 100;
		this.listenerOptions = {
			passive: true
		};

		this.onScroll = this.onScroll.bind(this);
		this.onScrollEnd = this.onScrollEnd.bind(this);
		this.slideTo = this.slideTo.bind(this);

		this.addEventListener = this.element.addEventListener.bind(this.element);
		this.removeEventListener = this.element.removeEventListener.bind(
			this.element
		);

		enabled && this.attachListeners();

		this.plugins = new Map();
		for (const plugin of plugins) {
			this.plugins.set(plugin.id, plugin);
			enabled && plugin.enable(this);
		}
	}

	attachListeners(): void {
		this.addEventListener('scroll', this.onScroll, this.listenerOptions);
	}

	onScroll(): void {
		if (this.scrollTimeoutId === null) {
			const direction = this.element.scrollLeft > this.slideScrollLeft ? 1 : -1;
			this.dispatch('slide-start', this.slide + direction);
		}

		const floored = this.calculateSlide();
		if (floored !== this.slide) {
			this.slideScrollLeft = this.element.scrollLeft;
			this.slide = floored;
			this.dispatch('slide-pass', this.slide);
		}

		this.scrollTimeoutId && clearTimeout(this.scrollTimeoutId);
		this.scrollTimeoutId = setTimeout(
			this.onScrollEnd,
			this.scrollTimeout
		) as unknown as number;
	}

	onScrollEnd(): void {
		if (this.element.scrollLeft % this.element.offsetWidth !== 0) return;

		this.scrollTimeoutId = null;
		this.slide = this.calculateSlide();
		this.slideScrollLeft = this.element.scrollLeft;
		this.dispatch('slide-stop', this.slide);
	}

	calculateSlide(): number {
		return this.roundingMethod(
			this.element.scrollLeft / this.element.offsetWidth
		);
	}

	dispatch(event: string, detail: number) {
		return this.element.dispatchEvent(
			new CustomEvent(event, {
				detail: detail
			})
		);
	}

	slideTo(index: number): void {
		this.element.scrollTo({
			left: index * this.element.offsetWidth
		});
	}

	destroy(): void {
		this.scrollTimeoutId && clearTimeout(this.scrollTimeoutId);
		this.removeEventListener('scroll', this.onScroll, this.listenerOptions);

		for (const plugin of this.plugins.values()) plugin.disable();
	}
}
