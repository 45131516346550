import { takeLatest, call, put, all } from 'redux-saga/effects';
import {
	fetchProfileFailure,
	fetchProfileStart,
	fetchProfileSuccess
} from './User.Slice';
import API_CONSTANTS, { API } from 'assets/Data/api-constants';

function* fetchProfile(action: ReturnType<typeof fetchProfileStart>) {
	try {
		const {
			payload: { id, showAssessment }
		} = action;

		const { data } = yield API.get(
			`${API_CONSTANTS.GET_PROFILE}?parent_id=${id}`
		);

		yield put(
			fetchProfileSuccess({
				userData: data.profile,
				childrenData: data.children,
				showAssessment
			})
		);
	} catch (error: any) {
		yield put(fetchProfileFailure(error.message));
	}
}

function* watchFetchProfileRequest() {
	yield takeLatest(fetchProfileStart.type, fetchProfile);
}

export default function* userSagas() {
	yield all([call(watchFetchProfileRequest)]);
}
