import { ErrorCross, PrimaryCloseIcon, SuccessCheckmark } from 'assets/images';
import { FC, ReactNode } from 'react';

const SchoolLoginPopup: FC<SchoolLoginPopupProps> = ({
	children,
	visible,
	handleClose,
	type = 'default',
	...restProps
}) => {
	const renderElement = () => {
		const { text, element } = restProps as RenderFormProps;

		switch (type) {
			case 'default':
				return children;

			default:
				return <RenderForm type={type} text={text} element={element} />;
		}
	};

	return visible ? (
		<div className='fixed z-50 w-full h-full top-0 left-0'>
			<div
				className='w-full h-full absolute top-0 left-0 z-40 bg-black bg-opacity-50'
				onClick={handleClose}
			/>
			<div className='position-center bg-white w-[30rem] z-50 h-84 rounded-xl'>
				{renderElement()}
				<img
					onClick={handleClose}
					className='w-8 h-8 absolute top-5 z-50 right-5 cursor-pointer'
					src={PrimaryCloseIcon}
					alt=''
				/>
			</div>
		</div>
	) : null;
};

const RenderForm: FC<RenderFormProps & { type: 'success' | 'error' }> = ({
	type,
	text,
	element
}) => (
	<div className='flex flex-col items-center gap-4 mt-15'>
		<img
			className='w-14 h-14'
			src={type === 'success' ? SuccessCheckmark : ErrorCross}
			alt=''
		/>
		<p className='text-2xl font-medium text-center'>{text}</p>
		<p className='text-center text-base tracking-wider text-gray-3'>
			{element}
		</p>
	</div>
);

export default SchoolLoginPopup;

interface RenderFormProps {
	text: string;
	element: ReactNode;
}

interface SchoolLoginPopupProps extends RenderFormProps {
	children: ReactNode;
	visible: boolean;
	handleClose: () => void;
	type?: 'default' | 'success' | 'error';
}
