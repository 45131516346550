import TextLogo from 'components/TextLogo/TextLogo';

const SchoolLoginLogos = () => (
	<div className='flex flex-col gap-4'>
		<img
			src='https://d37772fpgg0hig.cloudfront.net/assets/images/wonderHood-logo.png'
			className='h-17 w-17'
			alt=''
		/>
		<TextLogo className='!self-start !text-4xl' />
	</div>
);

export default SchoolLoginLogos;
