import styled from 'styled-components';

const UnorderedListContainer = styled.div`
	h1 {
		font-family: poppins;
		font-weight: 600;
		font-size: 18px;
		line-height: 27px;
		text-transform: capitalize;
		color: #1e8394;
		margin-bottom: 32px;

		@media (max-width: 480px) {
			font-size: 14px;
			line-height: 21px;
			margin-bottom: 16px;
		}
	}

	ul {
		li {
			max-width: 1201px;
			width: 100%;
			font-family: poppins;
			font-weight: 500;
			font-size: 14px;
			line-height: 21px;
			margin-bottom: 32px;

			@media (max-width: 480px) {
				width: 292px;
				font-size: 10px;
				line-height: 15px;
				margin-bottom: 16px;
			}
			list-style: none;

			a {
				color: #1e8394;
			}

			&:before {
				content: '●';
				color: #19a0b9;
				margin-right: 10px;
			}
		}
	}

	@media (max-width: 480px) {
		ul {
			li {
				&:before {
					margin-right: 5px;
				}
			}
		}
	}
`;

export default UnorderedListContainer;
