import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleToast } from 'assets/Data/utils';

const initialState: ReportsState = {
	assessment: {
		loading: false,
		error: null,
		data: null
	},
	students: {
		loading: false,
		error: null,
		data: null
	},
	pagination: {
		page: 1,
		limit: 10,
		grade: 'all'
	}
};

export const reportsSlice = createSlice({
	name: 'reports',
	initialState,
	reducers: {
		fetchAssessmentStart: (state, action: PayloadAction<AssessmentPayload>) => {
			state.assessment = {
				loading: true,
				error: null,
				data: state.assessment.data
			};

			handleToast({
				id: 'fetchAssessment',
				dataName: 'Assessment'
			});
		},
		fetchAssessmentSuccess: (
			state,
			action: PayloadAction<AssessmentResponse>
		) => {
			state.assessment = {
				loading: false,
				error: null,
				data: action.payload
			};
			handleToast({
				id: 'fetchAssessment',
				dataName: 'Assessment',
				status: 'resolved',
				hasNoData: typeof action.payload === 'object'
			});
		},
		fetchAssessmentFailure: (state, action: PayloadAction<string>) => {
			state.assessment = {
				loading: false,
				error: action.payload,
				data: null
			};
			handleToast({
				id: 'fetchAssessment',
				dataName: 'Assessment',
				status: 'rejected'
			});
		},
		saveAssessmentStart: (_, __: PayloadAction<AssessmentPayload>) => {
			handleToast({
				id: 'fetchAssessment',
				fullMessage: {
					pending: 'Sharing Assessment...',
					success: 'Assessment shared successfully!',
					error: 'Failed to share assessment!'
				}
			});
		},
		saveAssessmentSuccess: () => {
			handleToast({
				id: 'fetchAssessment',
				fullMessage: {
					pending: 'Sharing Assessment...',
					success: 'Assessment shared successfully!',
					error: 'Failed to share assessment!'
				},
				status: 'resolved'
			});
		},
		saveAssessmentFailure: () => {
			handleToast({
				id: 'fetchAssessment',
				fullMessage: {
					pending: 'Sharing Assessment...',
					success: 'Assessment shared successfully!',
					error: 'Failed to share assessment!'
				},
				status: 'rejected'
			});
		},
		fetchStudentsStart: (
			state,
			__: PayloadAction<{
				userId: string;
				page: number;
				limit: number;
				grade: string;
			}>
		) => {
			state.students = {
				loading: true,
				error: null,
				data: state.students.data
			};
			handleToast({
				id: 'fetchStudents'
			});
		},
		fetchStudentsSuccess: (state, action: PayloadAction<StudentsList>) => {
			state.students = {
				loading: false,
				error: null,
				data: action.payload
			};
			handleToast({
				id: 'fetchStudents',
				status: 'resolved',
				hasNoData: !!action.payload?.usersList?.length
			});
		},
		fetchStudentsFailure: (state, action: PayloadAction<string>) => {
			state.students = {
				loading: false,
				error: action.payload,
				data: null
			};
			handleToast({
				id: 'fetchStudents',
				status: 'rejected'
			});
		},
		changeLimit: (state, action: PayloadAction<number>) => {
			state.pagination.limit = action.payload;
		},
		changePage: (state, action: PayloadAction<number>) => {
			state.pagination.page = action.payload;
		},
		changeGrade: (state, action: PayloadAction<string>) => {
			state.pagination.grade = action.payload;
		},
		logoutReports: () => initialState
	}
});

export const {
	fetchAssessmentFailure,
	fetchAssessmentStart,
	fetchAssessmentSuccess,
	fetchStudentsFailure,
	fetchStudentsStart,
	fetchStudentsSuccess,
	logoutReports,
	changeLimit,
	changePage,
	changeGrade,
	saveAssessmentFailure,
	saveAssessmentStart,
	saveAssessmentSuccess
} = reportsSlice.actions;

const reportsReducer = reportsSlice.reducer;

export default reportsReducer;

interface AssessmentPayload {
	child_name: string;
	assessment: string[];
	rating_science: string;
	rating_maths: string;
	user_id: string;
	shareWithParent?: boolean;
}
