import { useAnalytics } from 'assets/Data/useAnalytics';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const SliderCard: FC<SliderCardProps> = ({
	thumbnail,
	name,
	url,
	progress = 0,
	slug,
	id,
	category
}) => {
	const { trackEvent } = useAnalytics();

	const navigate = useNavigate(),
		redirect = () => {
			trackEvent('Course Opened', {
				category: category,
				course_slug: slug,
				course_name: name,
				course_id: id
			});

			navigate(url);
		};

	return (
		<div
			onClick={redirect}
			className={`bg-gray-1 snap-start flex flex-col justify-start gap-2 p-2 w-fit rounded-md`}>
			<div className='flex flex-col w-full'>
				<img
					src={thumbnail}
					alt=''
					className='min-w-[9.75rem] h-23 lg:min-w-[11.5rem] lg:h-28 object-fill'
				/>
				<ProgressBar className='h-1 bg-primary w-0' width={progress} />
			</div>
			<h6 className='text-primary font-semibold tracking-widest text-xs max-w-[9.75rem] lg:max-w-[11.5rem]'>
				{name}
			</h6>
		</div>
	);
};

const ProgressBar = styled.span<{ width: string | number }>`
	width: ${({ width }) => width}% !important;
`;

export default SliderCard;

interface SliderCardProps extends SliderData {
	category: string;
}
