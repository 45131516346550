import PATH_CONSTANTS from 'assets/Data/path-constants';
import Login from 'pages/Auth/Login/Login';
import { Navigate, Route, Routes } from 'react-router-dom';

const UnauthenticatedRoutes = () => (
	<Routes key='unauthenticated-routes'>
		<Route path={PATH_CONSTANTS.DEFAULT} element={<Login />} />
		<Route
			path={PATH_CONSTANTS.ALL}
			element={<Navigate to={PATH_CONSTANTS.DEFAULT} />}
		/>
	</Routes>
);

export default UnauthenticatedRoutes;
