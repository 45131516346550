import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: UserState = {
	isAuthenticated: false,
	userData: null,
	childrenData: null,
	selectedChild: null,
	showAssessment: false
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		authenticationSuccess: state => {
			state.isAuthenticated = true;
		},
		fetchProfileStart: (
			_,
			__: PayloadAction<{
				id: string;
				showAssessment: boolean;
			}>
		) => {},
		fetchProfileSuccess: (
			state,
			action: PayloadAction<{
				userData: UserData;
				childrenData: Children;
				showAssessment: boolean;
			}>
		) => {
			state.userData = action.payload.userData;
			state.childrenData = action.payload.childrenData;
			state.isAuthenticated = true;
			state.showAssessment = action.payload.showAssessment;
		},
		fetchProfileFailure: (state, _: PayloadAction<string>) => {
			state.isAuthenticated = false;
			state.userData = null;
			state.childrenData = null;
		},
		logoutActualUser: () => initialState
	}
});

export const {
	authenticationSuccess,
	logoutActualUser,
	fetchProfileFailure,
	fetchProfileStart,
	fetchProfileSuccess
} = userSlice.actions;

const userReducer = userSlice.reducer;

export default userReducer;
