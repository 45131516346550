import { FC, useEffect, useMemo, useState } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { useRTKDispatch, useRTKSelector } from 'assets/Hooks/redux-hooks';
import {
	changeGrade,
	changeLimit,
	changePage,
	fetchStudentsStart,
	saveAssessmentStart
} from 'redux/Reports/Reports.Slice';
import Select, { MultiValue } from 'react-select';
import ThemeBtn from 'components/ThemeBtn/ThemeBtn';
import { BsFiletypeXls } from 'react-icons/bs';
import { FaFilePdf } from 'react-icons/fa6';
import { MdRemoveRedEye } from 'react-icons/md';
import clsx from 'clsx';
import Tooltip from 'components/Tooltip/Tooltip';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import Pagination from 'components/Pagination/Pagination';

const ReportsLayout = () => {
	const { data } = useRTKSelector(state => state.reportsReducer.students);
	const { limit, page, grade } = useRTKSelector(
		state => state.reportsReducer.pagination
	);

	const dispatch = useRTKDispatch();

	const userId = useRTKSelector(state => state.userReducer.userData?._id.$oid);

	const gradesOptions = useMemo(() => {
		const options = [
			{
				value: 'all',
				label: 'All Grades'
			}
		];

		if (!data?.grades?.length) return options;

		data.grades.forEach(grade => {
			options.push({
				value: grade,
				label: grade
			});
		});

		return options;
	}, [data?.grades]);

	useEffect(() => {
		if (!userId) return;

		dispatch(
			fetchStudentsStart({
				limit,
				page,
				userId,
				grade
			})
		);
	}, [dispatch, grade, limit, page, userId]);

	const getChildrenSpreadsheet = () => {
		if (!data?.usersList.length) return;

		const csvRows = [
			['"Parent Name"', '"Child Name"', '"Grade"', '"Phone"', '"Email"']
		];

		data.usersList.forEach(student => {
			csvRows.push([
				`"${student.parentName}"`,
				`"${student.childName}"`,
				`"${student.child_grade}"`,
				`"${student.phone}"`,
				`"${student.email}"`
			]);
		});

		const csvString = csvRows.map(row => row.join(',')).join('\n');
		const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvString}`);

		const a = document.createElement('a');
		a.href = encodedUri;
		a.target = '_blank';
		a.download = 'children.csv';

		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	const setPage = (page: number) => dispatch(changePage(page)),
		handleGradeChange = (grade: string) => dispatch(changeGrade(grade)),
		handleLimit = (limit: number) => dispatch(changeLimit(limit));

	return (
		<div className='flex flex-col items-center gap-16'>
			<div className='w-full flex justify-center mt-10'>
				<div className='bg-white-1 relative h-fit w-fit rounded-xl px-6 py-5 flex flex-col items-center gap-5'>
					<h1 className='text-3xl'>
						Students List ({data?.usersList.length} / {data?.totalCount})
					</h1>
					<p className='flex flex-col items-center text-red-500'>
						<span>
							1. Select the top skills displayed by child in the STEM lab ( you
							can select upto two)
						</span>
						<span>
							2. Select the child's level in Science based on your observation
							in the lab
						</span>
						<span>
							3. Select the child's level in Maths based on your observation in
							the lab
						</span>
						<span className='flex text-center gap-2'>
							4. click <MdRemoveRedEye className='top-1 relative' /> to generate
							the assessment or <FaFilePdf className='relative top-1' /> to
							download the assessment
						</span>
					</p>

					{/* Top Right absolute */}
					<div className='absolute top-5 right-5 cursor-pointer w-6 h-6'>
						<BsFiletypeXls
							className='w-6 h-6 text-primary'
							onClick={getChildrenSpreadsheet}
						/>
					</div>

					<ScrollToTop />

					<div className='flex flex-col items-baseline gap-4 w-full max-w-7xl'>
						<div className='flex min-w-2xl w-full items-center text-black py-5 px-2 rounded-xl bg-primary-10'>
							<p className='w-30 font-medium text-lg text-left'>Parent Name</p>
							<p className='w-30 font-medium text-lg text-center'>Child Name</p>
							<p className='w-30 font-medium text-lg text-center'>Age</p>
							<p className='w-30 font-medium text-lg text-center'>Phone</p>
							<p className='w-40 font-medium text-lg text-center'>Email</p>
							<p className='w-40 font-medium text-lg text-center'>1. Skills*</p>
							<p className='w-40 font-medium text-lg text-center'>
								2. Science Score*
							</p>
							<p className='w-40 font-medium text-lg text-right '>
								3. Maths Score*
							</p>
						</div>

						<StudentsTable />

						<Pagination
							page={page}
							totalPages={Math.ceil((data?.totalCount || 0) / limit)}
							setPage={setPage}>
							<div className='flex gap-3 items-center'>
								<div className='flex gap-2 items-center'>
									<span>Filter by Grade:</span>
									<Select
										className='w-40 cursor-pointer'
										options={gradesOptions}
										value={{
											value: grade,
											label: grade === 'all' ? 'All Grades' : grade
										}}
										onChange={grade => handleGradeChange(grade?.value || 'all')}
									/>
								</div>

								<div className='flex gap-2 items-center'>
									<span>Limit:</span>
									<Select
										className='cursor-pointer'
										options={limitOptions}
										value={{
											value: limit,
											label: limit.toString()
										}}
										onChange={limit => handleLimit(limit?.value || 10)}
									/>
								</div>
							</div>
						</Pagination>
					</div>
				</div>
			</div>

			<Outlet />
		</div>
	);
};

const StudentsTable = () => {
	const { data, error, loading } = useRTKSelector(
		state => state.reportsReducer.students
	);

	if (loading || !data?.usersList?.length || error) return null;

	return (
		<>
			{data.usersList.map(student => (
				<StudentItem {...student} key={student.parent_id} />
			))}
		</>
	);
};
const limitOptions = [
	{ value: 10, label: '10' },
	{ value: 20, label: '20' },
	{ value: 50, label: '50' },
	{ value: 100, label: '100' }
];

export default ReportsLayout;

const StudentItem: FC<StudentItemProps> = ({
	childName = 'Child Name',
	parentName = 'Parent Name',
	phone = 'Phone',
	primary = false,
	parent_id,
	email,
	child_grade
}) => {
	const { id } = useParams();
	const [skills, setSkills] = useState<
			{
				value: string;
				label: string;
			}[]
		>([]),
		[mathsScore, setMathsScore] = useState<string | undefined>(''),
		[scienceScore, setScienceScore] = useState<string | undefined>('');

	const navigate = useNavigate();

	const userId = useRTKSelector(state => state.userReducer.userData?._id.$oid);

	const dispatch = useRTKDispatch();

	const handleGenerate =
			(download = false) =>
			() => {
				if (!skills.length || !mathsScore || !scienceScore) return;

				const sessionId = Math.random().toString(36).substring(7);

				const queryParams = new URLSearchParams({
					name: childName.trim(),
					skills: JSON.stringify(skills.map(skill => skill.value)),
					science: scienceScore,
					maths: mathsScore,
					download: download.toString(),
					session: sessionId
				})
					.toString()
					.replaceAll(' ', '+');

				const url = `/reports/${parent_id}?${queryParams}`;

				navigate(url);
			},
		handleSkillsChange = (
			value: MultiValue<{
				value: string;
				label: string;
			}>
		) => {
			if (value.length > 2) return;

			setSkills([...value]);
		};

	const shareWithParent = () => {
		if (!skills.length || !mathsScore || !scienceScore || !parent_id)
			return console.log(skills, mathsScore, scienceScore, userId);

		dispatch(
			saveAssessmentStart({
				assessment: skills.map(skill => skill.value),
				child_name: childName,
				rating_science: scienceScore,
				rating_maths: mathsScore,
				user_id: parent_id,
				shareWithParent: true
			})
		);
	};

	const hasFilled = skills.length && mathsScore && scienceScore;

	return (
		<p
			className={clsx(
				'flex bg-gray-200 min-w-2xl w-full items-center justify-between text-black py-5 px-2 rounded-xl',
				{
					'border-primary border-2': id === parent_id,
					'bg-primary-10': primary,
					'bg-gray-200': !primary
				}
			)}>
			<p className='w-30 font-medium text-lg text-left'>{parentName}</p>
			<p className='w-30 font-medium text-lg text-center'>{childName}</p>
			<p className='w-30 font-medium text-lg text-center'>{child_grade}</p>
			<p className='w-30 font-medium text-lg text-center'>{phone}</p>
			<Tooltip condition={email.length > 16} text={email}>
				<p className='w-40 font-medium text-lg text-center truncate'>{email}</p>
			</Tooltip>

			<p className='w-40 font-medium text-lg text-center p-3'>
				<Select
					isMulti
					options={skillsOptions}
					onChange={handleSkillsChange}
					value={skills}
				/>
			</p>
			<p className='w-40 font-medium text-lg text-center p-3'>
				<Select
					options={scoreOptions}
					onChange={score => setScienceScore(score?.value)}
				/>
			</p>
			<p className='w-40 font-medium text-lg text-center p-3'>
				<Select
					options={scoreOptions}
					onChange={score => setMathsScore(score?.value)}
				/>
			</p>
			<div className='flex flex-col gap-1'>
				<Tooltip text='Generate Report'>
					<p className='font-medium text-lg'>
						<ThemeBtn disabled={!hasFilled} handleClick={handleGenerate()}>
							<MdRemoveRedEye className='w-4 h-4' />
						</ThemeBtn>
					</p>
				</Tooltip>
				<Tooltip text='Download as PDF'>
					<p className='font-medium text-lg'>
						<ThemeBtn disabled={!hasFilled} handleClick={handleGenerate(true)}>
							<FaFilePdf className='w-4 h-4' />
						</ThemeBtn>
					</p>
				</Tooltip>
			</div>
			<div className='m-3'>
				<ThemeBtn
					disabled={!hasFilled}
					className='p-0 m-0'
					handleClick={shareWithParent}>
					<p className='text-xs w-[2rem] text-center'>Share with Parent</p>
				</ThemeBtn>
			</div>
		</p>
	);
};

const skillsOptions = [
	{ value: 'listening', label: 'Listening Skills' },
	{ value: 'teamwork', label: 'Teamwork Skills' },
	{ value: 'creativity', label: 'Creativity' },
	{ value: 'problem_solving', label: 'Problem Solving' },
	{ value: 'curiosity', label: 'Curiosity' },
	{ value: 'attention_concentration', label: 'Attention and Concentration' }
];

const scoreOptions = [
	{
		label: 'Needs Attention',
		value: 'needs_improvement'
	},
	{
		value: 'average',
		label: 'Average'
	},
	{
		value: 'good',
		label: 'Good'
	},
	{
		value: 'excellent',
		label: 'Excellent'
	}
];

interface StudentItemProps extends Student {
	primary?: boolean;
}
