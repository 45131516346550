import { useRTKDispatch, useRTKSelector } from 'assets/Hooks/redux-hooks';
import { parentGift } from 'assets/images';
import { setParentalPopUp } from 'redux/App/App.Slice';
import { ChangeEvent, useEffect, useState } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';

const ParentalPopUp = () => {
	const [input, setInput] = useState(''),
		[error, setError] = useState(false),
		[[num1, num2], setNumbers] = useState([
			getRandomNumber(),
			getRandomNumber()
		]),
		callback: (() => void) | null = useRTKSelector(
			state => state.appReducer.parentalCallback
		),
		dispatch = useRTKDispatch();

	useEffect(() => {
		if (!error) return;

		setNumbers([getRandomNumber(), getRandomNumber()]);
		const timer = setTimeout(() => setError(false), 1500);
		return clearTimeout.bind(null, timer);
	}, [error]);

	const closePopUp = () => dispatch(setParentalPopUp(null)),
		handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
			setInput(value),
		handleSubmit = () => {
			if (!(+input === num1 * num2)) return setError(true);
			if (!(typeof callback === 'function')) return;
			callback();
			closePopUp();
		};

	return (
		<div
			className={`h-screen w-screen fixed z-[60] items-center justify-center ${
				!!callback ? 'flex' : 'hidden'
			}`}>
			<div
				className='fixed w-screen h-screen bg-black-fade z-10'
				onClick={closePopUp}
			/>
			<div className='flex items-center justify-center flex-col text-center md:max-w-[90%] relative w-[49rem] h-fit bg-white rounded-xl z-30 py-6 px-4 bg-bottom bg-contain bg-no-repeat bg-[url("https://d37772fpgg0hig.cloudfront.net/assets/images/assetNew/bottom_bg.webp")]'>
				<div className='absolute top-0 cursor-pointer z-[60] right-0 p-2'>
					<IoCloseCircleOutline onClick={closePopUp} className='w-6 h-6' />
				</div>
				<img src={parentGift} className='w-50' alt='' />
				<h3 className='font-semibold text-xl'>Are you a Parent?</h3>
				<p>To continue, Please enter the correct answer below.</p>
				{error && (
					<span className='text-red-600 text-sm mt-4'>
						Entered input is incorrect.
					</span>
				)}
				<div className='w-3/4 max-w-[25rem] flex items-center justify-between m-5'>
					<div className='h-10 w-10 rounded-[50%] bg-primary-15 grid place-items-center'>
						{num1}
					</div>
					<span className='text-xl'>x</span>
					<div className='h-10 w-10 rounded-[50%] bg-primary-15 grid place-items-center'>
						{num2}
					</div>
					<span className='text-xl'>=</span>
					<input
						type='tel'
						className='border-none bg-slate-300 h-8 w-19 outline-none rounded-md p-2 text-base '
						style={error ? { border: '1.5px solid red' } : {}}
						value={input}
						onChange={handleChange}
					/>
				</div>
				<button
					className='py-4 px-8 border-none outline-none rounded-md mb-8 bg-secondary-1'
					onClick={handleSubmit}>
					Submit
				</button>
			</div>
		</div>
	);
};

const getRandomNumber = () => Math.floor(Math.random() * 11);

export default ParentalPopUp;
