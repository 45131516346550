import { useRTKDispatch } from 'assets/Hooks/redux-hooks';
import { setParentalPopUp } from 'redux/App/App.Slice';
import { FC, useRef } from 'react';
import { Link } from 'react-router-dom';

const NavbarItem: FC<NavbarItemProps> = ({ type = 'link', ...restProps }) => {
	switch (type) {
		case 'link':
			return <NavbarLink {...restProps} />;

		case 'button':
			return (
				<button
					className={`text-sm border-b-[1px] border-black lg:border-none h-19 lg:h-fit grid place-items-center w-full lg:w-fit hover:text-primary cursor-pointer font-semibold tracking-wide`}
					onClick={restProps.handleClick}>
					{restProps.text}
				</button>
			);

		default:
			return <NavbarAnchor {...restProps} />;
	}
};

const NavbarLink: FC<NavbarItemProps> = ({ link, text, currentLocation }) => (
	<Link
		to={link}
		className={`text-sm border-b-[1px] border-black lg:border-none h-19 lg:h-fit grid place-items-center w-full lg:w-fit hover:text-primary cursor-pointer font-semibold tracking-wide ${
			link === currentLocation ? 'text-primary' : 'text-black'
		}`}>
		{text}
	</Link>
);

const NavbarAnchor: FC<NavbarItemProps> = ({ link, text, handleClick }) => {
	const dispatch = useRTKDispatch(),
		anchorRef = useRef<HTMLAnchorElement>(null),
		handleAnchorClick = () =>
			dispatch(
				setParentalPopUp(() =>
					handleClick ? handleClick() : anchorRef.current?.click()
				)
			);

	return (
		<div
			onClick={handleAnchorClick}
			className='text-sm border-b-[1px] text-black border-black lg:border-none h-19 lg:h-fit grid place-items-center w-full lg:w-fit hover:text-primary cursor-pointer font-semibold tracking-wide'>
			{text}

			<a ref={anchorRef} href={link} hidden>
				.
			</a>
		</div>
	);
};

export default NavbarItem;

interface NavbarItemProps {
	link: string;
	text: string;
	currentLocation: string;
	type?: 'link' | 'parental-anchor' | 'button';
	handleClick?: () => void;
}
