import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

const initialState: AuthState = {
	emailSentStatus: 'initial',
	passResetStatus: 'initial'
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loginUserStart: (_, __: PayloadAction<LoginUserPayload>) => {},
		loginUserSuccess: _ => {},
		loginUserFail: _ => {},
		setInitialEmailStatus: state => {
			state.emailSentStatus = 'initial';
		},
		sendLinkToEmailStart: (state, _: PayloadAction<string>) => {
			state.emailSentStatus = 'initial';
		},
		sendLinkToEmailSuccess: state => {
			state.emailSentStatus = 'success';
		},
		sendLinkToEmailFail: state => {
			state.emailSentStatus = 'error';
		},
		resetPasswordStart: (state, _: PayloadAction<ResetPasswordPayload>) => {
			state.passResetStatus = 'initial';
		},
		resetPasswordSuccess: state => {
			state.passResetStatus = 'success';
		},
		resetPasswordFail: state => {
			state.passResetStatus = 'error';
		},
		logoutAuthUser: () => initialState
	},
	extraReducers: builder => {
		builder.addCase(PURGE, () => initialState);
	}
});

export const {
	loginUserStart,
	loginUserSuccess,
	loginUserFail,
	resetPasswordFail,
	resetPasswordStart,
	resetPasswordSuccess,
	sendLinkToEmailFail,
	sendLinkToEmailStart,
	sendLinkToEmailSuccess,
	setInitialEmailStatus,
	logoutAuthUser
} = authSlice.actions;

const authReducer = authSlice.reducer;

export default authReducer;

interface LoginUserPayload {
	mail: string;
	password: string;
}

type ResetPasswordPayload = LoginUserPayload & {
	otp: string;
};
