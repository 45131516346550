import { useRTKDispatch, useRTKSelector } from 'assets/Hooks/redux-hooks';
import { useEffect, useRef, useState } from 'react';
import { fetchAssessmentStart } from 'redux/Reports/Reports.Slice';
import { useParams } from 'react-router-dom';
import { useQuery } from 'assets/Data/utils';
import jsPDF from 'jspdf';
import ThemeBtn from 'components/ThemeBtn/ThemeBtn';
import STEMProfile from './STEMProfile';
import PointsSheet from './Sheets/Points';
import Page from './Page';
import Skills from './Sheets/Skills';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

const ReportDetailWrapper = () => {
	const { id } = useParams();

	return (
		<ErrorBoundary>
			<ReportDetail key={id} />
		</ErrorBoundary>
	);
};

const ReportDetail = () => {
	const dispatch = useRTKDispatch();
	const selfRef = useRef<HTMLDivElement>(null);

	const [image1, setImage1] = useState<string | null>(null);
	const [image2, setImage2] = useState<string | null>(null);

	const printDocument = () => {
		if (!image1 || !image2) return;

		const pdf = new jsPDF('p', 'mm', 'a5');

		pdf.addImage({
			imageData: image1,
			x: 0,
			y: 0,
			width: pdf.internal.pageSize.getWidth(),
			height: pdf.internal.pageSize.getHeight(),
			format: 'PNG'
		});

		pdf.addPage();

		pdf.addImage({
			imageData: image2,
			x: 0,
			y: 0,
			width: pdf.internal.pageSize.getWidth(),
			height: pdf.internal.pageSize.getHeight(),
			format: 'PNG'
		});

		pdf.save(name.replaceAll(' ', '-') + '.pdf');
	};

	const {
		data: report,
		error,
		loading
	} = useRTKSelector(state => state.reportsReducer.assessment);

	const { id } = useParams();

	const {
		name,
		science: scienceScore,
		maths: mathsScore,
		skills,
		download,
		session
	} = useQuery();

	useEffect(() => {
		if (!id || !scienceScore || !mathsScore || !skills) return;

		dispatch(
			fetchAssessmentStart({
				assessment: JSON.parse(skills),
				child_name: name,
				rating_maths: mathsScore,
				rating_science: scienceScore,
				user_id: id
			})
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, scienceScore, mathsScore, skills]);

	useEffect(() => {
		if (!report) return;

		if (!(image1 || image2))
			return handleToast({
				id: 'render assessment' + id,
				message: 'Please wait while we prepare your report.'
			});

		handleToast({
			id: 'render assessment' + id,
			status: 'resolved',
			message: 'Your report is ready.'
		});

		if (download === 'true') return printDocument();

		window.scrollTo({
			top: selfRef.current?.offsetTop,
			behavior: 'smooth'
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [download, session, image1, image2]);

	if (loading || !report || error) return null;

	const {
		assessments: { science, maths, feedback, key_skills }
	} = report;

	return (
		<div
			ref={selfRef}
			className='w-full h-full flex justify-center items-center py-15'>
			<div className='max-w-5xl min-h-screen w-full flex-col justify-center items-center gap-10 flex'>
				{/* Print */}
				<ThemeBtn handleClick={printDocument} className='mb-10'>
					Download PDF
				</ThemeBtn>

				{/* Page 1 */}
				<Page
					waitTime={2}
					key={session + '-page-1'}
					name={name}
					setImage={setImage1}>
					<div className='grow overflow-hidden flex flex-col gap-5 items-center'>
						<div className='h-1/2 w-full pt-3 border border-black rounded-xl'>
							<STEMProfile report={report} />
						</div>

						<span className='w-full h-1 bg-black max-w-sm' />

						<div className='flex flex-col gap-5 w-full grow items-center h-full'>
							<p className='font-medium text-primary-25 text-4xl'>
								STEM Profile
							</p>
							<div className='flex gap-10 w-full h-full'>
								<PointsSheet
									points={science}
									title='Science Proficiency'
									color='bg-primary-3'
								/>
								<PointsSheet
									points={maths}
									title='Maths Proficiency'
									color='bg-primary-3'
								/>
							</div>
						</div>
					</div>
				</Page>

				{/* Profile 2 */}
				<Page
					waitTime={0.5}
					key={session + '-page-2'}
					name={name}
					setImage={setImage2}>
					<div className='flex flex-col items-center gap-5 w-full h-full'>
						<Skills
							skills={key_skills}
							heading='Key skills display in lab'
							color='bg-secondary-6'
						/>

						<PointsSheet
							points={feedback}
							title='Feedback'
							color='bg-tertiary'
						/>
					</div>
				</Page>
			</div>
		</div>
	);
};

export default ReportDetailWrapper;

const handleToast = ({
	id,
	status = 'pending',
	message
}: {
	id: string;
	status?: Status;
	message: string;
}) => {
	const promise = new Promise((resolve, reject) => {
		switch (status) {
			case 'pending':
				break;

			case 'resolved':
				resolve(null);
				break;

			case 'rejected':
				reject(null);
				break;
		}
	});

	toast?.promise(
		promise,
		{
			pending: message,
			success: message,
			error: message
		},
		{
			toastId: id,
			autoClose: status === 'resolved' ? 1 : 0
		}
	);
};
