import { FC, useReducer, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import libraryReducer, {
	InitialLibrary,
	setExpandError,
	toggleTagSelect
} from './LibraryReducer';
import useLibraryEffects from './useLibraryEffects';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { useRTKDispatch, useRTKSelector } from 'assets/Hooks/redux-hooks';
import { removeTags } from 'redux/Tag/Tag.Slice';
import TagSearch from 'components/TagSearch/TagSearch';
import PATH_CONSTANTS from 'assets/Data/path-constants';
import { DoodleBgTab, LibraryBg, LibraryBgDesktop } from 'assets/images';

const Library = () => {
	const [
			{
				appFeed: { data: appFeed },
				tagsQuery,
				courses: tagCourses,
				selectedTags,
				tagCoursesError,
				previousQuery
			},
			width
		]: [TagState, number] = useRTKSelector(state => [
			state.tagReducer,
			state.appReducer.window.width
		]),
		[{ courses, selectedTag, expandErrorActive }, dispatch] = useReducer(
			libraryReducer,
			InitialLibrary
		),
		reduxDispatch = useRTKDispatch(),
		errorContentRef = useRef<HTMLDivElement>(null);

	useLibraryEffects([
		appFeed,
		reduxDispatch,
		selectedTag,
		selectedTags,
		tagsQuery,
		previousQuery,
		tagCourses,
		dispatch,
		expandErrorActive,
		errorContentRef
	]);

	const handleSelectTag = (tag: LibraryTags) => () =>
			dispatch(toggleTagSelect(tag)),
		selectedLibTagBySelectedTags = (removedTag: SearchTag) => {
			const filteredTags = selectedTags.filter(t => t.slug !== removedTag.slug);
			const match = filteredTags.find(t =>
				['game', 'video'].some(s => s === t.slug)
			);
			if (!match) return dispatch(toggleTagSelect('none'));
			dispatch(toggleTagSelect(match.slug as LibraryTags));
		},
		handleRemoveTag = (tag: SearchTag) => () => {
			if (tag.slug === selectedTag) selectedLibTagBySelectedTags(tag);
			reduxDispatch(removeTags(tag));
		},
		handleTagAddition = (tag: SearchTag) => () => {
			if (selectedTag === 'all' && ['game', 'video'].some(t => t !== tag.slug))
				dispatch(toggleTagSelect('set-none'));
		},
		getErrorMessage = () => {
			if (!selectedTags.length && !(selectedTag === 'all'))
				return 'No courses to display!';
			return 'No courses found!';
		},
		toggleExpandError = () => {
			if (!tagCoursesError) return;
			dispatch(setExpandError(!expandErrorActive));
		};

	return (
		<LibraryWrapper className='flex max-h-screen h-screen flex-col justify-between items-center gap-5 lg:gap-0'>
			<div className='w-full h-fit self-start relative flex'>
				<img
					src={width > 767 ? LibraryBgDesktop : LibraryBg}
					alt=''
					className='relative object-contain'
				/>
				<h1 className='text-brown-700 text-2xl md:text-3xl lg:text-4xl absolute tracking-wide font-medium max-w-[11.5rem] md:max-w-[27rem] lg:max-w-full pl-7 pt-7 md:pt-14 lg:pt-24'>
					Learn at the comfort of your home...
				</h1>
			</div>
			<div className='flex flex-col gap-10 w-full h-full items-center pt-5'>
				<TagSearch
					handleAddTag={handleTagAddition}
					handleRemoveTag={handleRemoveTag}
				/>
				<div className='flex gap-3'>
					{tagOptions.map(optionTag => (
						<Tag
							handleClick={handleSelectTag}
							{...optionTag}
							key={optionTag.tag}
							isActive={selectedTag === optionTag.tag}
						/>
					))}
				</div>
				{courses.length ? (
					<CoursesWrapper className='bg-blue-fade flex-grow transition-500 bg-blend-multiply w-full flex flex-wrap flex-row justify-center gap-3 pt-7 pb-14 px-0 xs:px-3 rounded-t-xl overflow-hidden overflow-y-scroll theme-scroll'>
						{courses.map(course => (
							<CourseCard key={course.id} {...course} />
						))}
					</CoursesWrapper>
				) : (
					<div className='w-3/4 h-fit bg-primary-3 rounded-lg flex-center flex-col'>
						<div
							onClick={toggleExpandError}
							className={`flex-center p-2 bg-primary-4 rounded-lg justify-between w-full ${
								!!tagCoursesError ? 'cursor-pointer' : ''
							}`}>
							<span />
							<p className='font-medium font-fredoka'>{getErrorMessage()}</p>
							{(!!tagCoursesError && (
								<AiOutlineArrowDown
									className={`w-4 h-4 transition-500 ${
										expandErrorActive ? 'rotate-180' : ''
									}`}
								/>
							)) || <span />}
						</div>
						<div
							ref={errorContentRef}
							className='transition-500 w-full flex-center overflow-hidden font-medium font-fredoka h-0'>
							{tagCoursesError}
						</div>
					</div>
				)}
			</div>
		</LibraryWrapper>
	);
};

const LibraryWrapper = styled.div`
	background-image: url(${DoodleBgTab});
	background-size: contain;
	background-position: top;
	background-repeat: repeat;
`;

const CoursesWrapper = styled.div`
	background-image: url(${DoodleBgTab});
	background-size: contain;
	background-position: top;
	background-repeat: repeat;
`;

const tagOptions: {
	text: string;
	tag: LibraryTags;
}[] = [
	{
		text: 'All Courses',
		tag: 'all'
	},
	{
		text: 'Videos',
		tag: 'video'
	},
	{
		text: 'Games',
		tag: 'game'
	}
];

const Tag: FC<TagProps> = ({ text, isActive = false, handleClick, tag }) => (
	<div
		onClick={handleClick(isActive ? 'none' : tag)}
		className={`py-3 w-fit h-fit px-5 cursor-pointer mix-blend-multiply shadow-libraryTag rounded-md ${
			isActive ? 'bg-primary-18' : 'bg-white-1'
		}`}>
		<p className='font-medium text-xs tracking-widest'>{text}</p>
	</div>
);

const ProgressBar = styled.span<{ width: string | number }>`
	width: ${({ width }) => width}% !important;
`;

const CourseCard: FC<CourseCardProps> = ({
	thumbnail,
	course_name,
	progress = 0,
	slug
}) => {
	const navigate = useNavigate(),
		handleClick = () =>
			navigate(`${PATH_CONSTANTS.COURSE_PLAYER}?course-slug=${slug}`);

	return (
		<div
			onClick={handleClick}
			className='transition-500 cursor-pointer p-2 max-w-[10rem] min-w-[10rem] xs:max-w-[11rem] xs:min-w-[11rem] flex flex-col bg-white rounded-md mix-blend-unset'>
			<div className='flex-center flex-col'>
				<img src={thumbnail} alt='' className='w-full h-23' />
				<ProgressBar className='h-1 bg-primary w-0' width={progress} />
			</div>
			<p className='text-primary font-semibold tracking-widest text-xs max-w-[9.75rem] lg:max-w-[11.5rem]'>
				{course_name}
			</p>
		</div>
	);
};
export default Library;

type CourseCardProps = Content;

interface TagProps {
	text: string;
	isActive?: boolean;
	tag: LibraryTags;
	handleClick: (tag: LibraryTags) => () => void;
}
