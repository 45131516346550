import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: AppState = {
	parentalCallback: null,
	selectedChild: '',
	window: {
		width: window.innerWidth,
		height: window.innerHeight
	}
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		authenticateUser: (
			_,
			__: PayloadAction<{
				id: string;
				showAssessment: boolean;
			}>
		) => {},
		setParentalPopUp: (state, action: PayloadAction<null | (() => void)>) => {
			state.parentalCallback = action.payload;
		},
		selectChild: (state, action: PayloadAction<string>) => {
			state.selectedChild = action.payload;
		},
		resizeWindow: (
			state,
			action: PayloadAction<{ width: number; height: number }>
		) => {
			state.window = action.payload;
		},
		logoutUser: () => initialState
	}
});

export const {
	authenticateUser,
	logoutUser,
	setParentalPopUp,
	selectChild,
	resizeWindow
} = appSlice.actions;

const appReducer = appSlice.reducer;

export default appReducer;
