import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoCloseSharp } from 'react-icons/io5';
import { useRTKDispatch, useRTKSelector } from 'assets/Hooks/redux-hooks';
import PATH_CONSTANTS from 'assets/Data/path-constants';
import { SearchIcon } from 'assets/images';
import { addTags } from 'redux/Tag/Tag.Slice';

const TagSearch: FC<TagSearchProps> = ({ handleRemoveTag, handleAddTag }) => {
	// Declare the state variables for the input, suggestions, and the selected tags.
	const [input, setInput] = useState(''),
		[suggestions, setSuggestions] = useState<SearchTag[]>([]),
		inputRef = useRef<HTMLInputElement>(null),
		suggestionRef = useRef<HTMLDivElement>(null),
		[tags, setTags] = useState<SearchTag[]>([]),
		[appFeed, selectedTags]: [AppFeedRes | null, SearchTag[]] = useRTKSelector(
			state => [state.tagReducer.appFeed.data, state.tagReducer.selectedTags]
		),
		dispatch = useRTKDispatch(),
		navigate = useNavigate(),
		location = useLocation();

	useEffect(() => {
		if (!appFeed?.tags.length) return;

		// Filter the tags to remove duplicates and only include tags that match the input.
		const filteredTags = [...appFeed.tags].reduce((acc, curr) => {
			if (
				!acc.find(
					(item: { name: string }) =>
						item.name.toLowerCase() === curr.name.toLowerCase()
				)
			) {
				acc.push(curr);
			}
			return acc;
		}, [] as SearchTag[]);

		setTags(filteredTags);
	}, [appFeed?.tags]);

	// Update the suggestions state variable whenever the input or selected tags change.
	useEffect(() => {
		if (!input || !appFeed || !tags.length) return setSuggestions([]);

		// Split the filtered tags into two categories: those that start with the input, and those that include the input.
		const startingTags = tags.filter(tag =>
			tag.name.toLowerCase().startsWith(input.toLowerCase())
		);
		const includedTags = tags.filter(
			tag =>
				!tag.name.toLowerCase().startsWith(input.toLowerCase()) &&
				tag.name.toLowerCase().includes(input.toLowerCase())
		);

		// Combine the two categories of tags and filter out any tags that are already selected.
		setSuggestions(
			[...startingTags, ...includedTags].filter(
				tag => !selectedTags.find(item => item.slug === tag.slug)
			)
		);
	}, [appFeed, input, selectedTags, tags]);

	// Update the height of the suggestions container based on the number of suggestions.
	useEffect(() => {
		if (!suggestionRef.current) return;
		if (!suggestions.length) {
			suggestionRef.current.style.height = '0px';
			return;
		}

		// If there are more than five suggestions, set the height to 180 pixels. Otherwise, set it to 40 pixels per suggestion.
		suggestionRef.current.style.height =
			suggestions.length > 5 ? '180px' : `${suggestions.length * 40}px`;
	}, [suggestions.length]);

	// Define event handlers for changing the input, focusing the input, and handling clicks on suggestions.
	const changeInput = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
			setInput(value),
		focusInput = () => inputRef.current?.focus(),
		getSplitSuggestion = (text: string) =>
			text.replace(
				RegExp(input.trim().replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'gi'),
				val =>
					`<span class='text-black font-fredoka font-medium text-sm tracking-widest'>${val}</span>`
			),
		handleClick = (tag: SearchTag) => () => {
			dispatch(addTags(tag));
			typeof handleAddTag === 'function' && handleAddTag(tag)();

			setInput('');
			setSuggestions([]);

			if (!location.pathname.startsWith(PATH_CONSTANTS.LIBRARY))
				navigate(PATH_CONSTANTS.LIBRARY);
		};

	// Render the search input, suggestions container, and suggestions.
	return (
		<div className='relative'>
			<div
				className='flex relative justify-between items-center'
				onClick={focusInput}>
				<input
					ref={inputRef}
					value={input}
					onChange={changeInput}
					required
					type='text'
					inputMode='search'
					placeholder='Search'
					className={`transition-500 h-11 shadow-libraryTag mix-blend-multiply valid:mix-blend-normal relative z-0 px-3 py-2 w-screen max-w-[22rem] md:max-w-lg lg:max-w-[44rem] bg-white rounded-lg outline-none valid:bg-gray-8 tracking-widest font-fredoka font-medium text-xs text-black placeholder-primary ${
						suggestions.length && 'valid:rounded-b-none'
					}`}
				/>
				<img
					src={SearchIcon}
					alt=''
					className='absolute top-2.5 right-3 object-contain z-10'
				/>
			</div>
			<div
				className='absolute flex flex-col z-20 shadow-searchTagOverlay snap-scroll-container w-full overflow-hidden max-h-46 h-0 transition-500 ease-in-out bg-gray-5 overflow-y-scroll rounded-b-lg'
				ref={suggestionRef}>
				{suggestions.map(({ name, slug, type }, index) => (
					<div
						onClick={handleClick({ slug, name, type })}
						key={index + slug}
						className='flex items-center cursor-pointer p-2 px-3 hover:bg-slate-400'>
						<p
							className='text-gray-9 font-fredoka font-medium text-sm tracking-widest'
							dangerouslySetInnerHTML={{
								__html: getSplitSuggestion(name)
							}}
						/>
					</div>
				))}
			</div>
			{location.pathname.startsWith(PATH_CONSTANTS.LIBRARY) &&
				!!selectedTags.length && (
					<div className='pt-5 w-full flex gap-3 items-center flex-wrap justify-center'>
						{selectedTags.map((tag, i) => (
							<Tag {...tag} key={tag.name + i} handleClick={handleRemoveTag} />
						))}
					</div>
				)}
		</div>
	);
};

const Tag: FC<TagProps> = ({
	name,
	handleClick = () => () => null,
	slug,
	type
}) => (
	<div className='border-primary py-2 px-2 gap-8 border-solid border-[0.5px] bg-white rounded-md w-fit flex items-center justify-between'>
		<span></span>
		<p className='capitalize font-fredoka font-medium text-sm text-primary-17 tracking-widest'>
			{name}
		</p>
		<IoCloseSharp
			onClick={handleClick({ slug, name, type })}
			className='cursor-pointer w-4 h-4'
		/>
	</div>
);

export default TagSearch;

type TagProps = {
	handleClick?: (tag: SearchTag) => () => void;
} & SearchTag;

interface TagSearchProps {
	handleRemoveTag?: (tag: SearchTag) => () => void;
	handleAddTag?: (tag: SearchTag) => () => void;
}
