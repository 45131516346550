import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

export const childAvatarUrl = (avatarNumber: string | number) =>
	`https://d37772fpgg0hig.cloudfront.net/assets/images/LMS/profile/avatars/${avatarNumber}.webp`;

export const themeColors = [
	{
		colorTop: '#F8D7E8',
		colorBottom: '#E3A7C0'
	},
	{
		colorTop: '#E9E9E9',
		colorBottom: '#C1C1C1'
	},
	{
		colorTop: '#88D4D9',
		colorBottom: '#62BACA'
	},
	{
		colorTop: '#ECE9BC',
		colorBottom: '#E7E091'
	}
];

interface QueryParams {
	[key: string]: any;
}

export const useQuery = (): QueryParams => {
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	let queryObject: QueryParams = {};

	for (let pair of params.entries()) {
		if (queryObject[pair[0]]) {
			// If the key already exists, add the new value to the array of values
			if (Array.isArray(queryObject[pair[0]])) {
				queryObject[pair[0]].push(decodeURIComponent(pair[1]));
			} else {
				queryObject[pair[0]] = [
					queryObject[pair[0]],
					decodeURIComponent(pair[1])
				];
			}
		} else {
			// If the key doesn't exist, just add the value
			queryObject[pair[0]] = decodeURIComponent(pair[1]);
		}
	}

	return queryObject;
};

export const handleToast = ({
	id,
	error = 'Something went wrong!',
	status = 'pending',
	hasNoData = false,
	dataName = 'data',
	fullMessage
}: {
	id: string;
	error?: string;
	status?: Status;
	hasNoData?: boolean;
	dataName?: string;
	fullMessage?: {
		pending: string;
		success: string;
		error: string;
	};
}) => {
	const promise = new Promise((resolve, reject) => {
		switch (status) {
			case 'pending':
				break;

			case 'resolved':
				resolve(null);
				break;

			case 'rejected':
				reject(null);
				break;
		}
	});

	toast?.promise(
		promise,
		fullMessage || {
			pending: `Fetching ${dataName}...`,
			success: hasNoData
				? `Fetched ${dataName} successfully!`
				: `${dataName} not found!`,
			error: error
		},
		{
			toastId: id,
			autoClose: hasNoData ? 1 : 0
		}
	);
};
