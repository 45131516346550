import { FC } from 'react';
import { FaStar } from 'react-icons/fa';

const ReportDetailHeader: FC<{
	name: string;
}> = ({ name }) => (
	<div className='flex flex-col p-5 py-2 pt-0 w-full items-center'>
		<Title />
		<Name name={name} />
	</div>
);

export default ReportDetailHeader;

const Title = () => (
	<div className='flex items-center justify-center w-fit gap-5'>
		<FaStar size={30} color='#fed15c' />
		<h1 className='text-primary-25 text-medium text-center text-3xl text-shadow-xl -mt-5'>
			STEM Assessment Report Of
		</h1>
		<FaStar size={30} color='#fed15c' />
	</div>
);

const Name: FC<{
	name: string;
}> = ({ name }) => (
	<div className='w-full flex justify-center items-center rounded-xl bg-quaternary mt-5'>
		<p className='w-fit flex font-medium text-xl text-center gap-3 pb-5'>
			<span className='text-primary-25'>Name:</span>
			<span className='text-primary'>{name}</span>
		</p>
	</div>
);
