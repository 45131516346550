import { FC } from 'react';
import styled from 'styled-components';

const HeadContainer = styled.div`
	h2 {
		font-family: poppins;
		font-weight: 600;
		font-size: 18px;
		line-height: 27px;
		text-transform: capitalize;
		color: #1e8394;
		margin-bottom: 32px;

		@media (max-width: 480px) {
			font-size: 14px;
			line-height: 21px;
			margin-bottom: 16px;
		}
	}

	p {
		max-width: 1201px;
		width: 100%;
		font-family: poppins;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		margin-bottom: 32px;

		@media (max-width: 480px) {
			width: 292px;
			font-size: 10px;
			line-height: 15px;
			margin-bottom: 16px;
		}
	}
`;

const PolicySubHead: FC<PolicySubHeadProps> = ({
	heading = null,
	children
}) => (
	<HeadContainer>
		{heading && <h2>{heading}</h2>}
		{children && <div>{children}</div>}
	</HeadContainer>
);

interface PolicySubHeadProps {
	heading?: string;
	children?: React.ReactNode;
}

export default PolicySubHead;
