import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import NavbarItem from './NavbarItem';
import { isBrowser } from 'react-device-detect';
import { IoCloseSharp } from 'react-icons/io5';
import { useRTKDispatch, useRTKSelector } from 'assets/Hooks/redux-hooks';
import { logoutUser, selectChild, setParentalPopUp } from 'redux/App/App.Slice';
import PATH_CONSTANTS from 'assets/Data/path-constants';
import { childAvatarUrl } from 'assets/Data/utils';
import API_CONSTANTS, { API } from 'assets/Data/api-constants';
import TextLogo from 'components/TextLogo/TextLogo';

const Navbar = () => {
	const location = useLocation(),
		[avatar, setAvatar] = useState('0'),
		[children, selectedChild, parent, showAssessment, width]: [
			Children | null,
			string | null,
			UserData | null,
			boolean,
			number
		] = useRTKSelector(state => [
			state.userReducer.childrenData,
			state.userReducer.selectedChild,
			state.userReducer.userData,
			state.userReducer.showAssessment,
			state.appReducer.window.width
		]),
		dispatch = useRTKDispatch(),
		[
			[isLeftSidebarActive, isRightSidebarActive, isUpgradeConfirmationActive],
			setSidebar
		] = useState([false, false, false]);

	const collapseSidebar = () => setSidebar([false, false, false]),
		toggleLeftSidebar = () => setSidebar([!isLeftSidebarActive, false, false]),
		toggleRightSidebar = () => {
			// setSidebar([false, !isRightSidebarActive, false])
		},
		toggleUpgradeConfirmation = () =>
			setSidebar([false, false, !isUpgradeConfirmationActive]),
		handleUpgrade = async () => {
			if (!parent) return;
			const {
				_id: { $oid: parent_id },
				first_name: First_Name,
				last_name: Last_Name,
				phone: Phone,
				email: Email
			} = parent;

			await API.post(API_CONSTANTS.UPGRADE_CONFIRMATION, {
				parent_id
			});

			const platform = isBrowser ? 'Website' : mobileOs();

			const zoho_data = {
				First_Name,
				Last_Name,
				Lead_Source: `LSM app upgrade - ${platform}`,
				Mobile: Phone,
				Phone,
				Email
			};

			await API.post(API_CONSTANTS.ZOHOLEAD, zoho_data);

			toast.info("Thank you for your interest! We'll reach out too you soon.");
		},
		handleConfirmation = () =>
			dispatch(setParentalPopUp(toggleUpgradeConfirmation)),
		getLogo = () => {
			if (width > 1024) return <TextLogo />;

			return (
				<img
					alt=''
					className='w-10 h-10 -mt-2 object-contain'
					src='https://d37772fpgg0hig.cloudfront.net/assets/images/LMS/assets/wonderlearn-mini-logo.webp'
				/>
			);
		},
		logout = () => dispatch(logoutUser());

	useEffect(() => {
		if (!children?.length) return;
		if (!selectedChild) {
			dispatch(selectChild(children[0]._id.$oid));
			setAvatar(children[0].avatar);
			return;
		}

		const child = children.find(child => child._id.$oid === selectedChild);
		if (!child) return;

		setAvatar(child.avatar);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [children, selectedChild]);

	return (
		<>
			<div
				className={`fixed w-screen h-screen z-40 bg-black-fade ${
					isLeftSidebarActive ||
					isRightSidebarActive ||
					isUpgradeConfirmationActive
						? 'block'
						: 'hidden'
				}`}
				onClick={collapseSidebar}
			/>
			<div
				onClick={collapseSidebar}
				className={`fixed flex-center z-50 left-[50%] w-[90%] md:w-full h-full [transform:translate(-50%,_-50%)] transition-500 ${
					isUpgradeConfirmationActive ? 'top-[50%]' : '-top-[100%]'
				}`}>
				<div className='flex-center w-fit relative flex-col gap-4 p-6 bg-white rounded-xl'>
					<div className='absolute cursor-pointer z-50 -top-4 -right-4 w-fit rounded-full h-10 grid place-items-center bg-[rgba(118,224,245,0.8)]'>
						<IoCloseSharp className='w-6 h-6 m-0 p-2' />
					</div>
					<h1 className='text-primary-8 font-semibold text-2xl'>
						Great Choice
					</h1>
					<div className='flex-center flex-col gap-3'>
						<p className='text-base max-w-xs text-center'>
							Get unlimited content, live teacher classes and our specially
							designed learning toys with complete subscription.
						</p>
						<div className='flex gap-3'>
							<button
								onClick={collapseSidebar}
								className='border-2 border-solid border-primary-8 text-primary-8 bg-white py-1 px-2 rounded-md cursor-pointer'>
								Not Yet
							</button>
							<button
								onClick={handleUpgrade}
								className='bg-primary-8 border-2 border-solid border-primary-8 text-white py-1 px-2 rounded-md cursor-pointer'>
								Confirm
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className='flex z-50 bg-white justify-between px-6 md:px-4 shadow-nav lg:px-18 py-1 items-center'>
				<Link to='/'>
					<ShrinkLogo className='relative left-10 lg:right-0'>
						{getLogo()}
					</ShrinkLogo>
				</Link>
				<div className='flex gap-2 md:gap-7 items-center'>
					<div
						className={`flex gap-7 z-50 lg:z-30 items-center justify-start w-2/3 max-w-xs h-screen flex-col absolute lg:relative lg:flex-row lg:h-fit lg:max-w-none lg:w-full left-0 top-0 bg-white transition-500 ${
							isLeftSidebarActive ? '' : 'lg:translate-x-0 -translate-x-full'
						}`}>
						<div className='relative z-50 flex flex-col items-center w-full'>
							<div
								className={`lg:hidden flex-center top-[2%] w-10 h-10 relative cursor-pointer transition-500 ${
									isLeftSidebarActive ? 'left-[40%]' : 'left-[63%]'
								}`}
								onClick={toggleLeftSidebar}>
								<span
									className={`burger-icon self-center bg-slate-900 ${
										isLeftSidebarActive ? 'open' : ''
									}`}
								/>
							</div>
							<div className='flex flex-col w-full px-3 lg:flex-row lg:px-0 lg:gap-7'>
								<NavbarItem
									link={PATH_CONSTANTS.DEFAULT}
									text='Home'
									currentLocation={location.pathname}
								/>
								<NavbarItem
									link={PATH_CONSTANTS.LIBRARY}
									text='Library'
									currentLocation={location.pathname}
								/>
								{showAssessment && (
									<NavbarItem
										link={PATH_CONSTANTS.REPORTS}
										text='Assessment'
										currentLocation={location.pathname}
									/>
								)}
								<NavbarItem
									type='button'
									link={'/logout'}
									handleClick={logout}
									text='Logout'
									currentLocation={location.pathname}
								/>
								<div className='flex flex-col lg:hidden'>
									<NavbarItem
										type='parental-anchor'
										link={PATH_CONSTANTS.PARENTS_COMMUNITY_GROUP}
										text="Parent's Community"
										currentLocation={location.pathname}
									/>
									<NavbarItem
										type='parental-anchor'
										link={PATH_CONSTANTS.SUPPORT}
										text='Support'
										currentLocation={location.pathname}
									/>
								</div>
							</div>
						</div>
					</div>
					<button
						onClick={handleConfirmation}
						className='lg:hidden cursor-pointer hover:text-primary text-sm [border:1px_solid_black] px-3 py-[2px] rounded-2xl'>
						Upgrade
					</button>
					<img
						src={childAvatarUrl(avatar)}
						onClick={toggleRightSidebar}
						alt=''
						className='w-12 h-12'
					/>
				</div>
			</div>
		</>
	);
};

// const ProfileItem: FC<ProfileItemProps> = ({
// 	name,
// 	avatar,
// 	handleClick,
// 	isSelected = false
// }) => (
// 	<div
// 		className='flex gap-4 items-center p-3 cursor-pointer'
// 		onClick={handleClick}>
// 		<img className='w-14 h-14' src={avatar} alt='' />
// 		<p
// 			className={`font-fredoka font-medium text-sm tracking-widest hover:text-primary ${
// 				isSelected ? 'text-primary' : ''
// 			}`}>
// 			{name}
// 		</p>
// 	</div>
// );

const ShrinkLogo = styled.div`
	h1 {
		font-size: 2.5rem !important;
	}

	@media (max-width: 768px) {
		h1 {
			font-size: 1.5rem !important;
		}
	}
`;

const mobileOs = () => {
	const userAgent = navigator?.userAgent || '';
	const IOS = ['iPhone', 'iPad', 'iPod'];
	if (userAgent.includes('Android')) return 'Android';
	else if (IOS.some(val => userAgent.includes(val))) return 'IOS';
	return 'unknown mobile';
};

export default Navbar;

// interface ProfileItemProps {
// 	name: string;
// 	avatar: string;
// 	handleClick: () => void;
// 	isSelected?: boolean;
// }
