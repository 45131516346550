import { RxEyeOpen, RxEyeClosed } from 'react-icons/rx';
import { FC, useState } from 'react';
import styled from 'styled-components';
import hexToFilter from 'assets/Data/HexToFilter';

const SchoolFormInput: FC<SchoolFormInputProps> = ({
	label,
	value,
	onChange,
	name,
	id,
	placeholder
}) => {
	const [visible, setVisible] = useState(false),
		isEmail = id === 'email';

	const toggleVisibility = () => setVisible(prevState => !prevState);

	return (
		<div className='w-94 flex flex-col gap-4 relative'>
			<label className='text-base font-medium text-black-4' htmlFor={id}>
				{label}
			</label>
			<input
				id={id}
				name={name}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				type={!isEmail ? (visible ? 'text' : 'password') : 'email'}
				className='h-12 bg-primary-24 border-none text-sm flex items-center pl-4 outline-none rounded-lg'
			/>
			<button
				className='text-primary absolute right-5 bottom-4'
				onClick={toggleVisibility}>
				{!isEmail && (visible ? <StyledEyeOpen /> : <StyledEyeClosed />)}
			</button>
		</div>
	);
};

const primaryHex = '#018093',
	filterPrimary = hexToFilter(primaryHex).filter;

const StyledEyeOpen = styled(RxEyeOpen)`
	${filterPrimary}
`;

const StyledEyeClosed = styled(RxEyeClosed)`
	${filterPrimary}
`;

export default SchoolFormInput;

interface SchoolFormInputProps {
	label: string;
	value: string;
	id: string;
	placeholder: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	name: string;
}
