import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { IoArrowBackOutline } from 'react-icons/io5';
import PolicySubHead from 'components/PolicySubHead/PolicySubHead';
import PolicyHead from 'components/PolicyHead/PolicyHead';
import UnorderedList from 'components/UnorderedList/UnorderedList';

const PrivacyPolicyContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin-bottom: 155px;

	.container {
		width: 100%;
		max-width: 1280px;
	}

	@media (max-width: 480px) {
		margin-bottom: 39px;
	}

	a {
		color: #1e8394;
	}
	@media (max-width: 480px) {
		padding: 0 30px;
	}
`;

const PrivacyPolicy: FC = () => {
	const navigate = useNavigate();
	useEffect(() => {
		window.scrollTo({ behavior: 'smooth', top: 0 });
	}, []);

	return (
		<PrivacyPolicyContainer>
			<>
				{window.innerWidth <= 768 && (
					<IoArrowBackOutline
						className='arrow-back'
						onClick={navigate.bind(null, -1)}
						style={{ margin: '20px 0 -20px' }}
					/>
				)}
				<div className='container'>
					<PolicyHead
						heading='Privacy Policy'
						subHead='Midhas Wonderhood pvt. ltd. Privacy Policy'
						text='This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from wonderhood-store.myshopify.com (the “Site”).'
					/>
					<PolicySubHead heading='Personal information we collect'>
						<p>
							When you visit the Site, we automatically collect certain
							information about your device, including information about your
							web browser, IP address, time zone, and some of the cookies that
							are installed on your device. Additionally, as you browse the
							Site, we collect information about the individual web pages or
							products that you view, what websites or search terms referred you
							to the Site, and information about how you interact with the Site.
							We refer to this automatically-collected information as “Device
							Information”.
						</p>
					</PolicySubHead>
					<UnorderedList heading='We collect Device Information using the following technologies'>
						<span>
							Cookies” are data files that are placed on your device or computer
							and often include an anonymous unique identifier. For more
							information about cookies, and how to disable cookies, visit{' '}
							<a href='https://www.allaboutcookies.org'>
								https://www.allaboutcookies.org
							</a>
							.
						</span>
						<span>
							“Log files” track actions occurring on the Site, and collect data
							including your IP address, browser type, Internet service
							provider, referring/exit pages, and date/time stamps.
						</span>
						<span>
							Web beacons”, “tags”, and “pixels” are electronic files used to
							record information about how you browse the Site.
						</span>
					</UnorderedList>
					<PolicySubHead>
						<p>
							Additionally when you make a purchase or attempt to make a
							purchase through the Site, we collect certain information from
							you, including your name, billing address, shipping address,
							payment information (including credit card numbers , email
							address, and phone number. We refer to this information as “Order
							Information”.
						</p>
						<p>
							When we talk about “Personal Information” in this Privacy Policy,
							we are talking both about Device Information and Order
							Information.
						</p>
					</PolicySubHead>
					<PolicySubHead heading='How do we use your personal information?'>
						<p>
							We use the Order Information that we collect generally to fulfill
							any orders placed through the Site (including processing your
							payment information, arranging for shipping, and providing you
							with invoices and/or order confirmations). Additionally, we use
							this Order Information to:
						</p>
					</PolicySubHead>
					<UnorderedList>
						<span>Communicate with you</span>
						<span>Screen our orders for potential risk or fraud</span>
						<span>
							When in line with the preferences you have shared with us, provide
							you with information or advertising relating to our products or
							services
						</span>
					</UnorderedList>
					<PolicySubHead>
						<p>
							We use the Device Information that we collect to help us screen
							for potential risk and fraud (in particular, your IP address), and
							more generally to improve and optimize our Site (for example, by
							generating analytics about how our customers browse and interact
							with the Site, and to assess the success of our marketing and
							advertising campaigns). We also use this information for the
							purpose of re-targeting and marketing
						</p>
					</PolicySubHead>
					<PolicySubHead>
						<p>
							We share your Personal Information with third parties to help us
							use your Personal Information, as described above. For example, we
							use Shopify to power our online store--you can read more about how
							Shopify uses your Personal Information here:{' '}
							<a href='https://www.shopify.com/legal/privacy'>
								https://www.shopify.com/legal/privacy
							</a>
							. We also use Google Analytics to help us understand how our
							customers use the Site -- you can read more about how Google uses
							your Personal Information here:{' '}
							<a href='https://www.google.com/intl/en/policies/privacy/'>
								https://www.google.com/intl/en/policies/privacy/
							</a>
							. You can also opt-out of Google Analytics here:{' '}
							<a href='https://tools.google.com/dlpage/gaoptout'>
								https://tools.google.com/dlpage/gaoptout
							</a>
							.
						</p>
						<p>
							Finally, we may also share your Personal Information to comply
							with applicable laws and regulations, to respond to a subpoena,
							search warrant or other lawful request for information we receive,
							or to otherwise protect our rights.
						</p>
					</PolicySubHead>
					<PolicySubHead heading='Behavioral Advertising'>
						<p>
							As described above, we use your Personal Information to provide
							you with targeted advertisements or marketing communications we
							believe may be of interest to you. For more information about how
							targeted advertising works, you can visit the Network Advertising
							Initiative’s (“NAI”) educational page at{' '}
							<a href='https://www.networkadvertising.org/understanding-online-advertising/how-does-it-work'>
								https://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
							</a>
							.
						</p>
						<p>
							You can opt out of targeted advertising by using the links below
						</p>
					</PolicySubHead>
					<UnorderedList>
						<span>
							{' '}
							Facebook:{' '}
							<a href='https://www.facebook.com/settings/?tab=ads'>
								https://www.facebook.com/settings/?tab=ads
							</a>
						</span>
						<span>
							Google:{' '}
							<a href='https://www.google.com/settings/ads/anonymous'>
								https://www.google.com/settings/ads/anonymous
							</a>
						</span>
						<span>
							Bing:{' '}
							<a href='https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads'>
								https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
							</a>
						</span>
					</UnorderedList>
					<PolicySubHead>
						<p>
							Additionally, you can opt out of some of these services by
							visiting the Digital Advertising Alliance’s opt-out portal at:{' '}
							<a href='https://optout.aboutads.info/'>
								https://optout.aboutads.info/
							</a>
							.
						</p>
					</PolicySubHead>
					<PolicySubHead heading='Do Not Track'>
						<p>
							Please note that we do not alter our Site’s data collection and
							use practices when we see a Do Not Track signal from your browser.
						</p>
					</PolicySubHead>
					<PolicySubHead heading='Data Retention'>
						<p>
							When you place an order through the Site, we will maintain your
							Order Information for our records unless and until you ask us to
							delete this information.
						</p>
					</PolicySubHead>
					<PolicySubHead heading='Changes'>
						<p>
							We may update this privacy policy from time to time in order to
							reflect, for example, changes to our practices or for other
							operational, legal or regulatory reasons.
						</p>
					</PolicySubHead>
					<PolicySubHead heading='contact Us'>
						<p>
							For more information about our privacy practices, if you have
							questions, or if you would like to make a complaint, please
							contact us by e-mail at{' '}
							<a href='mailto:support@wonderhood.in'>support@wonderhood.in</a>{' '}
							or by mail using the details provided below:
						</p>
						<p>Midhas Wonderhood Private Limited</p>
						<p>[Re: Privacy Compliance Officer]</p>
						<p>A 28 SECTOR 14 Noida Uttar Pradesh IN 201301</p>
					</PolicySubHead>
				</div>
			</>
		</PrivacyPolicyContainer>
	);
};
export default PrivacyPolicy;
