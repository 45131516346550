import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: TagState = {
	selectedTags: [],
	tagsQuery: '',
	appFeed: {
		data: null,
		error: null
	},
	featured: {
		courses: null,
		error: null
	},
	courses: [],
	tagCoursesError: '',
	selectedTag: 'set-none',
	previousQuery: ''
};

export const tagSlice = createSlice({
	name: 'tag',
	initialState,
	reducers: {
		fetchAppFeedStart: _ => {},
		fetchAppFeedSuccess: (state, action: PayloadAction<AppFeed>) => {
			state.appFeed = {
				data: action.payload,
				error: null
			};
		},
		fetchAppFeedFailure: (state, action: PayloadAction<string>) => {
			state.appFeed = {
				data: null,
				error: action.payload
			};
		},
		fetchFeaturedCoursesStart: (_, __: PayloadAction<Grade>) => {},
		fetchFeaturedCoursesSuccess: (state, action: PayloadAction<Courses>) => {
			state.featured = {
				courses: action.payload,
				error: null
			};
		},
		fetchFeaturedCoursesFailure: (state, action: PayloadAction<string>) => {
			state.featured = {
				courses: null,
				error: action.payload
			};
		},
		setTags: (state, action: PayloadAction<SearchTag[]>) => {
			const { selectedTags, tagsQuery } = getModifiedTagsState(action.payload);
			state.selectedTags = selectedTags;
			state.tagsQuery = tagsQuery;
		},
		clearTags: state => {
			const { selectedTags, tagsQuery } = getModifiedTagsState([]);
			state.selectedTags = selectedTags;
			state.tagsQuery = tagsQuery;
		},
		addTags: (state, action: PayloadAction<SearchTag[] | SearchTag>) => {
			const { selectedTags, tagsQuery } = addTag(
				state.selectedTags,
				action.payload
			);
			state.selectedTags = selectedTags;
			state.tagsQuery = tagsQuery;
		},
		removeTags: (state, action: PayloadAction<SearchTag[] | SearchTag>) => {
			const { selectedTags, tagsQuery } = removeTag(
				state.selectedTags,
				action.payload
			);
			state.selectedTags = selectedTags;
			state.tagsQuery = tagsQuery;
		},
		fetchCoursesFromTagsStart: (_, __: PayloadAction<string>) => {},
		fetchCoursesFromTagsSuccess: (
			state,
			action: PayloadAction<{
				courses: Content[];
				previousQuery: string;
			}>
		) => {
			const { courses, previousQuery } = action.payload;
			state.courses = courses;
			state.previousQuery = previousQuery;
		},
		fetchCoursesFromTagsFailure: (state, action: PayloadAction<string>) => {
			state.tagCoursesError = action.payload;
		},
		logoutTagUser: () => initialState
	}
});

export const {
	logoutTagUser,
	fetchAppFeedStart,
	fetchAppFeedSuccess,
	fetchAppFeedFailure,
	fetchFeaturedCoursesStart,
	fetchFeaturedCoursesSuccess,
	fetchFeaturedCoursesFailure,
	fetchCoursesFromTagsStart,
	fetchCoursesFromTagsSuccess,
	fetchCoursesFromTagsFailure,
	setTags,
	clearTags,
	addTags,
	removeTags
} = tagSlice.actions;

const tagReducer = tagSlice.reducer;

export default tagReducer;

const courseTypes = ['game', 'video'];

const addTag = (
		tags: SearchTag[],
		queryTags: SearchTag[] | SearchTag
	): ModifiedTagsState => {
		if (!Array.isArray(queryTags)) queryTags = [queryTags];

		const tagsToAdd: SearchTag[] = [];
		for (const tag of queryTags)
			if (!tags.find(t => t.slug === tag.slug)) tagsToAdd.push(tag);

		const courseTypeToSelect = tagsToAdd.find(tag =>
			courseTypes.some(type => tag.slug === type)
		);
		const selectedCourseType = tags.find(tag =>
			courseTypes.some(type => tag.slug === type)
		);

		if (courseTypeToSelect && selectedCourseType) {
			const selectedCourseTypeIndex = tags.indexOf(selectedCourseType);
			tags.splice(selectedCourseTypeIndex, 1);
		}

		if (!tagsToAdd.length) return getModifiedTagsState(tags);

		return getModifiedTagsState([...tags, ...tagsToAdd]);
	},
	removeTag = (
		tags: SearchTag[],
		queryTags: SearchTag[] | SearchTag
	): ModifiedTagsState => {
		if (!Array.isArray(queryTags)) queryTags = [queryTags];

		const tagsToRemove: SearchTag[] = [];
		for (const tag of queryTags)
			if (tags.find(t => t.slug === tag.slug)) tagsToRemove.push(tag);

		if (!tagsToRemove.length) return getModifiedTagsState(tags);

		return getModifiedTagsState([
			...tags.filter(t => tagsToRemove.some(tag => t.slug !== tag.slug))
		]);
	},
	getModifiedTagsState = (tags: SearchTag[]): ModifiedTagsState => ({
		selectedTags: tags,
		tagsQuery: tags.length
			? `query=${encodeURIComponent(
					JSON.stringify(
						tags.map(tag => ({
							value: tag.name,
							type: tag.type
						}))
					)
			  )}`
			: ''
	});

interface ModifiedTagsState {
	selectedTags: SearchTag[];
	tagsQuery: string;
}
