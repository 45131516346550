import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { IoContract, IoExpand } from 'react-icons/io5';
import { useQuery } from 'assets/Data/utils';
import API_CONSTANTS, { API } from 'assets/Data/api-constants';
import PATH_CONSTANTS from 'assets/Data/path-constants';
import Spinner from 'components/Spinner/Spinner';
import BackButton from 'components/BackButton/BackButton';
import hexToFilter from 'assets/Data/HexToFilter';
import { CoursePlayerBg, OnboardingBgDesktop, PlayBtn } from 'assets/images';
import { addTags } from 'redux/Tag/Tag.Slice';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import { useAnalytics } from 'assets/Data/useAnalytics';

const CoursePlayer = () => {
	const { 'course-slug': courseName } = useQuery(),
		navigate = useNavigate(),
		dispatch = useDispatch(),
		[course, setCourse] = useState<CourseProblem | null | string>(null),
		[activeProblem, setActiveProblem] = useState<number>(0),
		selfRef = useRef<HTMLDivElement>(null),
		[isFullScreen, setIsFullScreen] = useState<boolean>(false),
		gameRef = useRef<HTMLDivElement>(null);

	const fetchCourseProblem = useCallback(async () => {
		try {
			const { data } = await API.get(
				`${API_CONSTANTS.PROBLEM_TAGS_FROM_COURSE}?course_slug=${courseName}`
			);
			setCourse(data);
		} catch (error) {
			setCourse('Something went wrong! Please try again later.');
		}
	}, [courseName]);

	useEffect(() => {
		if (!courseName) return navigate(-1);

		fetchCourseProblem();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [courseName, fetchCourseProblem]);

	useEffect(() => {
		if (!course) return;
		if (typeof course === 'string') return;
		if (course.filtered) return;

		const problems = course.problems.reduce((acc, currentVal) => {
			if (!acc.find(val => val.slug === currentVal.slug)) acc.push(currentVal);
			return acc;
		}, [] as unknown as Problem[]);
		setCourse({ ...course, problems, filtered: true });
	}, [course]);

	useEffect(() => {
		if (isFullScreen) gameRef.current?.requestFullscreen();
		else document?.exitFullscreen();
	}, [isFullScreen]);

	useEffect(() => {
		if (!gameRef.current) return;
		gameRef.current.addEventListener('fullscreenchange', () => {
			if (document.fullscreenElement) setIsFullScreen(true);
			else setIsFullScreen(false);
		});
	}, []);

	const {
		url: activeCourseUrl,
		name: activeCourseName,
		type: activeCourseType,
		_id
	} = useMemo(() => {
		if (!course) return {} as Problem;
		if (typeof course === 'string') return {} as Problem;
		return course.problems[activeProblem];
	}, [activeProblem, course]);

	const { trackEvent } = useAnalytics();

	useEffect(() => {
		if (
			!(activeCourseName && activeCourseType && activeCourseUrl && courseName)
		)
			return;

		trackEvent('Course Played', {
			course_name: activeCourseName,
			course_type: activeCourseType,
			course_url: activeCourseUrl,
			course_id: _id.$oid,
			course_slug: courseName
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeCourseUrl, activeCourseName, activeCourseType, courseName]);

	const selectCourse = (index: number) => () => {
			setActiveProblem(index);
			selfRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
		},
		getPlayer = () => {
			switch (activeCourseType) {
				case 'videos':
					return (
						<VideoPlayer
							url={activeCourseUrl}
							courseName={courseName?.replaceAll('-', ' ')}
							videoName={activeCourseName}
						/>
					);

				default:
					return (
						<div ref={gameRef} className='w-full h-full flex-center relative'>
							<iframe
								className='w-full h-full aspect-square lg:aspect-video'
								src={activeCourseUrl}
								title={activeCourseName}
							/>
							<div
								onClick={() => setIsFullScreen(!isFullScreen)}
								className='w-7 h-7 cursor-pointer absolute bottom-[5%] right-[5%]'>
								{isFullScreen ? (
									<IoExpand
										style={{ filter: FilterColor }}
										size={28}
										fill='#ffffff'
									/>
								) : (
									<IoContract
										style={{ filter: FilterColor }}
										size={28}
										fill='#ffffff'
									/>
								)}
							</div>
						</div>
					);
			}
		},
		handleTagClick = (tag: SearchTag) => () => {
			dispatch(addTags(tag));
			navigate(PATH_CONSTANTS.LIBRARY);
		},
		getSideItems = () => {
			if (!(typeof course === 'object')) return;

			switch (activeCourseType) {
				case 'videos':
					return (
						<CourseProblemWrapper className='flex flex-col justify-center items-center lg:justify-start gap-4 lg:gap-8 py-4'>
							{course?.problems.map(({ name, _id: { $oid: id } }, i) => (
								<CourseProblemCard
									key={id}
									index={i}
									title={name}
									selectCourse={selectCourse}
									isActive={activeProblem === i}
								/>
							))}
						</CourseProblemWrapper>
					);

				default:
					return (
						<div className='px-6 pb-15 lg:p-0'>
							<div className='game-items p-6 min-w-xs2 w-full h-97 rounded-md flex flex-col gap-4 lg:gap-7 lg:rounded-xl lg:border-[0.5px] border-solid border-primary lg:max-h-full'>
								<h1 className='font-medium text-base lg:text-xl tracking-widest'>
									Let’s learn together with wonderLearn
								</h1>
								<div className='flex flex-wrap gap-4 w-full lg:max-w-sm'>
									{course?.tags.map((tag, i) => (
										<GameTags
											handleClick={handleTagClick}
											tag={tag}
											key={tag.slug + i + activeCourseName}
										/>
									))}
								</div>
							</div>
						</div>
					);
			}
		};

	if (!course)
		return (
			<div className='w-screen flex justify-center p-3'>
				<Spinner />
			</div>
		);

	if (typeof course === 'string')
		return <div className='w-screen flex justify-center p-3'>{course}</div>;

	return (
		<CoursePlayerWrapper
			ref={selfRef}
			className='pt-9 lg:gap-10 lg:py-9 lg:px-14'>
			<div className='flex flex-col gap-9 lg:bg-white-fade lg:py-9 lg:px-14'>
				<div className='flex items-center gap-4 lg:gap-6 pl-2 lg:-ml-4'>
					<BackButton to={PATH_CONSTANTS.DEFAULT} />
					<h1 className='font-medium text-lg lg:text-2xl tracking-widest capitalize'>
						{activeCourseType === 'videos'
							? courseName?.replaceAll('-', ' ')
							: course.problems[activeProblem].name}
					</h1>
				</div>
				<div className='flex flex-col gap-9 lg:gap-14 lg:flex-row'>
					<div className='w-full'>{getPlayer()}</div>
					{getSideItems()}
				</div>
			</div>
		</CoursePlayerWrapper>
	);
};

const FilterColor = hexToFilter('#018093')
	.filter.replace('filter:', '')
	.replace(',', '')
	.replace(';', '');

const GameTags: FC<GameTagsProps> = ({ tag, handleClick }) => (
	<div
		onClick={handleClick(tag)}
		className='border-primary cursor-pointer py-2 px-10 border-solid border-[0.5px] bg-white rounded-md'>
		<p className='capitalize font-fredoka font-medium text-sm text-primary-17 tracking-widest'>
			{tag.name}
		</p>
	</div>
);

const CourseProblemCard: FC<CourseCardProps> = ({
	title,
	isActive = false,
	index,
	selectCourse
}) => (
	<div
		onClick={selectCourse(index)}
		className={`flex cursor-pointer w-78 flex-col gap-3 shadow-courseProblem rounded-md p-4 pt-5 ${
			isActive ? 'bg-primary-10' : 'bg-white'
		}`}>
		<p className='font-semibold text-sm max-w-[17.5rem]'>{title}</p>
		<img src={PlayBtn} alt='' className='w-10 h-10' />
	</div>
);

const CourseProblemWrapper = styled.div`
	background-image: url(${CoursePlayerBg});
	background-size: cover;
	background-position: bottom;
	background-repeat: no-repeat;

	@media (min-width: 1024px) {
		background-image: url('');
	}
`;

const CoursePlayerWrapper = styled.div`
	.game-items {
		background: linear-gradient(
			180deg,
			#d7eff4 0%,
			rgba(255, 255, 255, 0) 100%
		);
	}
	@media (min-width: 1024px) {
		background-image: url(${OnboardingBgDesktop});
		background-size: cover;
		background-position: top;
		background-repeat: no-repeat;
	}
`;

export default CoursePlayer;

interface CourseCardProps {
	title: string;
	isActive?: boolean;
	index: number;
	selectCourse: (index: number) => () => void;
}

interface GameTagsProps {
	tag: SearchTag;
	handleClick: (tag: SearchTag) => () => void;
}

interface CourseProblem {
	course_id: CourseID;
	problems: Problem[];
	status: number;
	tags: SearchTag[];
	filtered?: boolean;
}

interface CourseID {
	$oid: string;
}

interface Problem {
	_id: CourseID;
	created_at: DatedAt;
	deleted: boolean;
	description: string;
	name: string;
	order: number;
	slug: string;
	type: string;
	updated_at: DatedAt;
	url: string;
}
