import API_CONSTANTS, { API } from 'assets/Data/api-constants';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import {
	fetchAppFeedFailure,
	fetchAppFeedStart,
	fetchAppFeedSuccess,
	fetchCoursesFromTagsFailure,
	fetchCoursesFromTagsStart,
	fetchCoursesFromTagsSuccess,
	fetchFeaturedCoursesFailure,
	fetchFeaturedCoursesStart,
	fetchFeaturedCoursesSuccess
} from './Tag.Slice';

function* fetchAppFeed() {
	try {
		const { data } = yield API.get(API_CONSTANTS.FEEDS);
		yield put(fetchAppFeedSuccess(data));
	} catch (error) {
		yield put(fetchAppFeedFailure('Something went wrong!'));
	}
}

function* fetchCoursesFromTags(
	action: ReturnType<typeof fetchCoursesFromTagsStart>
) {
	try {
		const { data } = yield API.get(
			`${API_CONSTANTS.SEARCH_BY_TAGS}?${action.payload}`
		);
		if (!(data.status < 300)) {
			yield put(fetchCoursesFromTagsFailure(data.message));
			return;
		}
		yield put(
			fetchCoursesFromTagsSuccess({
				courses: data.courses,
				previousQuery: action.payload
			})
		);
	} catch (error: any) {
		yield put(fetchCoursesFromTagsFailure(error.message));
	}
}

function* watchFetchCoursesFromTags() {
	yield takeLatest(fetchCoursesFromTagsStart.type, fetchCoursesFromTags);
}

function* fetchFeaturedCourses(
	action: ReturnType<typeof fetchFeaturedCoursesStart>
) {
	try {
		const { data } = yield API.get(
			`${API_CONSTANTS.FEATURED}?grade=${action.payload}&deviceWidth=${window.innerWidth}`
		);

		yield put(fetchFeaturedCoursesSuccess(data.courses));
	} catch (error) {
		yield put(fetchFeaturedCoursesFailure('Something went wrong!'));
	}
}

function* watchFetchFeaturedCourses() {
	yield takeLatest(fetchFeaturedCoursesStart.type, fetchFeaturedCourses);
}

function* watchFetchAppFeed() {
	yield takeLatest(fetchAppFeedStart.type, fetchAppFeed);
}

export default function* tagSagas() {
	yield all([
		call(watchFetchAppFeed),
		call(watchFetchFeaturedCourses),
		call(watchFetchCoursesFromTags)
	]);
}
