import { createAction } from 'redux/Root/Root.Actions';
import { Reducer } from 'react';

export const InitialVideoState = {
	visible: true,
	mousePosition: {
		clientX: 0,
		clientY: 0
	},
	playing: false,
	ended: false,
	aborted: false,
	canPlay: false,
	hasError: false,
	duration: 0,
	bufferPercent: 0,
	currentTimePercent: 0,
	isFullScreen: false,
	loading: false,
	loadedMetaData: false
};

export enum VIDEO_CONSTANTS {
	SET_VISIBILITY = '@video/SET_VISIBILITY',
	SET_MOUSE_POSITION = '@video/SET_MOUSE_POSITION',
	TOGGLE_PLAYING = '@video/TOGGLE_PLAYING',
	SET_VIDEO_DURATION = '@video/SET_VIDEO_DURATION',
	SET_VIDEO_BUFFER = '@video/SET_VIDEO_BUFFER',
	SET_VIDEO_CURRENT_TIME = '@video/SET_VIDEO_CURRENT_TIME',
	SET_VIDEO_ENDED = '@video/SET_VIDEO_ENDED',
	SET_VIDEO_ABORTED = '@video/SET_VIDEO_ABORTED',
	SET_VIDEO_CAN_PLAY = '@video/SET_VIDEO_CAN_PLAY',
	SET_VIDEO_HAS_ERROR = '@video/SET_VIDEO_HAS_ERROR',
	SET_FULLSCREEN = '@video/SET_FULLSCREEN',
	SET_VIDEO_CURRENT_TIME_PERCENT = '@video/SET_VIDEO_CURRENT_TIME_PERCENT',
	SET_PLAYING_START = '@video/SET_PLAYING_START',
	SET_PLAYING_END = '@video/SET_PLAYING_END',
	RESET_VIDEO_STATE = '@video/RESET_VIDEO_STATE',
	SET_VIDEO_LOADING = '@video/SET_VIDEO_LOADING',
	SET_VIDEO_LOADED_METADATA = '@video/SET_VIDEO_LOADED_METADATA'
}

const videoReducer: Reducer<typeof InitialVideoState, VideoActions> = (
	state = InitialVideoState,
	action
): typeof InitialVideoState => {
	switch (action.type) {
		case VIDEO_CONSTANTS.SET_VISIBILITY:
			return { ...state, visible: action.payload };

		case VIDEO_CONSTANTS.SET_MOUSE_POSITION:
			return { ...state, mousePosition: action.payload };

		case VIDEO_CONSTANTS.TOGGLE_PLAYING:
			return { ...state, playing: !state.playing };

		case VIDEO_CONSTANTS.SET_VIDEO_ENDED:
			return { ...state, ended: action.payload };

		case VIDEO_CONSTANTS.SET_VIDEO_ABORTED:
			return { ...state, aborted: action.payload };

		case VIDEO_CONSTANTS.SET_VIDEO_CAN_PLAY:
			return { ...state, canPlay: action.payload };

		case VIDEO_CONSTANTS.SET_VIDEO_HAS_ERROR:
			return { ...state, hasError: action.payload };

		case VIDEO_CONSTANTS.SET_VIDEO_DURATION:
			return { ...state, duration: action.payload };

		case VIDEO_CONSTANTS.SET_VIDEO_BUFFER:
			return {
				...state,
				bufferPercent: (action.payload / state.duration) * 100
			};

		case VIDEO_CONSTANTS.SET_VIDEO_CURRENT_TIME:
			return {
				...state,
				currentTimePercent: (action.payload / state.duration) * 100
			};

		case VIDEO_CONSTANTS.SET_FULLSCREEN:
			return { ...state, isFullScreen: action.payload };

		case VIDEO_CONSTANTS.SET_VIDEO_CURRENT_TIME_PERCENT:
			return { ...state, currentTimePercent: action.payload };

		case VIDEO_CONSTANTS.SET_PLAYING_START:
			return { ...state, playing: true };

		case VIDEO_CONSTANTS.SET_PLAYING_END:
			return { ...state, playing: false };

		case VIDEO_CONSTANTS.SET_VIDEO_LOADING:
			return { ...state, loading: action.payload };

		case VIDEO_CONSTANTS.SET_VIDEO_LOADED_METADATA:
			return { ...state, loadedMetaData: true };

		case VIDEO_CONSTANTS.RESET_VIDEO_STATE:
			return InitialVideoState;

		default:
			return state;
	}
};

export default videoReducer;

export type VideoActions =
	| ReturnType<typeof setVisibility>
	| ReturnType<typeof setMousePosition>
	| ReturnType<typeof togglePlaying>
	| ReturnType<typeof setVideoDuration>
	| ReturnType<typeof setVideoBuffer>
	| ReturnType<typeof setVideoCurrentTime>
	| ReturnType<typeof setVideoEnded>
	| ReturnType<typeof setVideoAborted>
	| ReturnType<typeof setVideoCanPlay>
	| ReturnType<typeof setVideoHasError>
	| ReturnType<typeof setFullScreen>
	| ReturnType<typeof setVideoCurrentTimePercent>
	| ReturnType<typeof setPlayingStart>
	| ReturnType<typeof setPlayingEnd>
	| ReturnType<typeof resetVideoState>
	| ReturnType<typeof setVideoLoading>
	| ReturnType<typeof setVideoLoadedMetaData>;

export const setVisibility = (visible: boolean) =>
	createAction(VIDEO_CONSTANTS.SET_VISIBILITY, visible);

export const setMousePosition = (mousePosition: {
	clientX: number;
	clientY: number;
}) => createAction(VIDEO_CONSTANTS.SET_MOUSE_POSITION, mousePosition);

export const togglePlaying = () => createAction(VIDEO_CONSTANTS.TOGGLE_PLAYING);

export const setVideoDuration = (duration: number) =>
	createAction(VIDEO_CONSTANTS.SET_VIDEO_DURATION, duration);

export const setVideoBuffer = (buffer: number) =>
	createAction(VIDEO_CONSTANTS.SET_VIDEO_BUFFER, buffer);

export const setVideoCurrentTime = (currentTime: number) =>
	createAction(VIDEO_CONSTANTS.SET_VIDEO_CURRENT_TIME, currentTime);

export const setVideoEnded = (ended: boolean) =>
	createAction(VIDEO_CONSTANTS.SET_VIDEO_ENDED, ended);

export const setVideoAborted = (aborted: boolean) =>
	createAction(VIDEO_CONSTANTS.SET_VIDEO_ABORTED, aborted);

export const setVideoCanPlay = (canPlay: boolean) =>
	createAction(VIDEO_CONSTANTS.SET_VIDEO_CAN_PLAY, canPlay);

export const setVideoHasError = (hasError: boolean) =>
	createAction(VIDEO_CONSTANTS.SET_VIDEO_HAS_ERROR, hasError);

export const setFullScreen = (isFullscreen: boolean) =>
	createAction(VIDEO_CONSTANTS.SET_FULLSCREEN, isFullscreen);

export const setVideoCurrentTimePercent = (percent: number) =>
	createAction(VIDEO_CONSTANTS.SET_VIDEO_CURRENT_TIME_PERCENT, percent);

export const setPlayingStart = () =>
	createAction(VIDEO_CONSTANTS.SET_PLAYING_START);

export const setPlayingEnd = () =>
	createAction(VIDEO_CONSTANTS.SET_PLAYING_END);

export const resetVideoState = () =>
	createAction(VIDEO_CONSTANTS.RESET_VIDEO_STATE);

export const setVideoLoading = (loading: boolean) =>
	createAction(VIDEO_CONSTANTS.SET_VIDEO_LOADING, loading);

export const setVideoLoadedMetaData = () =>
	createAction(VIDEO_CONSTANTS.SET_VIDEO_LOADED_METADATA);
