import Saga from 'redux-saga';
import {
	persistStore,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER
} from 'redux-persist';
import { configureStore, Middleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { persistedReducer } from './Root/Root.Reducer';
import rootSaga from './Root/Root.Saga';

const sagaMiddleware = Saga();

const middlewares: Middleware[] = [sagaMiddleware];

if (process.env.NODE_ENV === 'development') middlewares.push(logger);

const Store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware => [
		...getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
			},
			thunk: false
		}),
		...middlewares
	]
});

export const persistedStore = persistStore(Store);

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof Store.getState>;
export type RootDispatch = typeof Store.dispatch;

export default Store;
