import { RefObject, useEffect, type Dispatch as ReactDispatch } from 'react';
import { AnyAction, Dispatch } from 'redux';

import { LibraryActions, setCourses, toggleTagSelect } from './LibraryReducer';
import {
	addTags,
	clearTags,
	fetchAppFeedStart,
	fetchCoursesFromTagsStart,
	removeTags
} from 'redux/Tag/Tag.Slice';

export default function useLibraryEffects([
	appFeed,
	reduxDispatch,
	selectedTag,
	selectedTags,
	tagsQuery,
	previousQuery,
	tagCourses,
	dispatch,
	expandErrorActive,
	errorContentRef
]: libraryEffectsDeps) {
	useEffect(() => {
		return () => {
			reduxDispatch(clearTags());
			return;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!appFeed?.feed.length) {
			reduxDispatch(fetchAppFeedStart());
			return;
		}

		switch (selectedTag) {
			case 'all':
				reduxDispatch(removeTags(libraryTags));
				const content = appFeed.feed.find(
					({ header }) => header === 'Start Courses'
				)?.content;
				if (!content) return;
				dispatch(setCourses(content));
				return;

			case 'game':
				reduxDispatch(
					addTags({
						name: 'Games',
						slug: 'game',
						type: 'tag'
					})
				);
				return;

			case 'video':
				reduxDispatch(
					addTags({
						name: 'Videos',
						slug: 'video',
						type: 'tag'
					})
				);
				return;

			case 'none':
				reduxDispatch(removeTags(libraryTags));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appFeed, selectedTag]);

	useEffect(() => {
		if (!selectedTags.length && selectedTag === 'none')
			return dispatch(setCourses([]));

		if (
			previousQuery === tagsQuery ||
			selectedTag === 'all' ||
			!selectedTags.length ||
			!tagsQuery
		)
			return;
		reduxDispatch(fetchCoursesFromTagsStart(tagsQuery));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTag, selectedTags, tagsQuery, previousQuery]);

	useEffect(() => {
		if (selectedTag === 'all') return;
		if (!selectedTags.length) return;
		dispatch(setCourses(tagCourses));
	}, [selectedTag, dispatch, tagCourses, selectedTags.length]);

	useEffect(() => {
		if (selectedTags.find(tag => tag.slug === 'video'))
			dispatch(toggleTagSelect('video'));
		else if (selectedTags.find(tag => tag.slug === 'game'))
			dispatch(toggleTagSelect('game'));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTags]);

	useEffect(() => {
		if (!selectedTags.length && selectedTag === 'set-none') {
			const content = appFeed?.feed.find(
				({ header }) => header === 'Start Courses'
			)?.content;
			if (!content) return;
			dispatch(toggleTagSelect('all'));
			return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appFeed?.feed, selectedTag, selectedTags.length]);

	useEffect(() => {
		if (!errorContentRef.current) return;
		if (!expandErrorActive) {
			errorContentRef.current.style.height = '0px';
			return;
		}

		errorContentRef.current.style.height =
			errorContentRef.current.scrollHeight + 20 + 'px';
	}, [errorContentRef, expandErrorActive]);
}

type libraryEffectsDeps = [
	AppFeedRes | null,
	Dispatch<AnyAction>,
	LibraryTags,
	SearchTag[],
	string,
	string,
	Content[],
	ReactDispatch<LibraryActions>,
	boolean,
	RefObject<HTMLDivElement>
];

const libraryTags: [SearchTag, SearchTag] = [
	{
		name: 'Games',
		slug: 'game',
		type: 'tag'
	},
	{
		name: 'Videos',
		slug: 'video',
		type: 'tag'
	}
];
