import { FC } from 'react';

const PointsSheet: FC<PointsSheetProps> = ({ title, color, points }) => (
	<div
		className={`rounded-lg flex flex-col py-5 px-8 gap-8 w-full h-full ${color}`}>
		<p className='text-center text-2xl'>{title}</p>
		<ul className='w-full h-full flex flex-col gap-5'>
			{points?.map((point, i) => (
				<li key={'point' + title + i} className='bullet list-none text-lg'>
					{point}
				</li>
			))}
		</ul>
	</div>
);

export default PointsSheet;

interface PointsSheetProps {
	title: string;
	color: string;
	points?: string[];
}
