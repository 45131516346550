import { FC } from 'react';
import { Radar } from 'react-chartjs-2';
import {
	Chart,
	RadarController,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	DoughnutController,
	ArcElement,
	Title,
	CategoryScale,
	Tooltip,
	Legend
} from 'chart.js';
import ProgressChart from './ProgressChart';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(
	RadarController,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	DoughnutController,
	ArcElement,
	Title,
	CategoryScale,
	Tooltip,
	ChartDataLabels,
	Legend
);

const STEMProfile: FC<{
	report: AssessmentResponse;
}> = ({ report }) => (
	<div className='flex flex-col justify-center items-center w-full h-full'>
		<p className='text-gray-6 uppercase font-semibold text-lg w-fit'>
			My STEM profile
		</p>
		<div className='flex w-full h-full justify-center items-center'>
			<div className='h-full aspect-square flex justify-center'>
				<Radar
					key='radar'
					id='radar'
					data={{
						labels: Object.keys(report.scores),
						datasets: [
							{
								label: 'Assessment Report',
								data: Object.values(report.scores),
								fill: true,
								backgroundColor: 'rgba(255, 99, 132, 0.2)',
								borderColor: 'rgb(255, 99, 132)',
								pointBackgroundColor: 'rgb(255, 99, 132)',
								pointBorderColor: '#fff',
								pointHoverBackgroundColor: '#fff',
								pointHoverBorderColor: 'rgb(255, 99, 132)'
							}
						]
					}}
					options={{
						elements: {
							line: {
								borderWidth: 1
							}
						},
						scales: {
							r: {
								ticks: {
									stepSize: 10
								},
								min: 0,
								max: 100
							}
						}
					}}
				/>
			</div>
			<div className='w-full h-[70%] flex'>
				<ProgressChart
					data={report.progress?.map(({ value }) => value)}
					labels={report.progress?.map(({ label }) => label)}
					colors={report.progress?.map(({ color }) => color)}
				/>
			</div>
		</div>
	</div>
);

export default STEMProfile;
