import { FC } from 'react';
import styled from 'styled-components';

const HeadContainer = styled.div`
	h1 {
		font-family: poppins;
		font-weight: 600;
		font-size: 36px;
		line-height: 54px;
		text-align: center;
		color: #1e8394;
		margin: 72px 0 58px;

		@media (max-width: 480px) {
			font-size: 24px;
			line-height: 36px;
			margin-bottom: 16px;
		}

		p {
			font-family: poppins;
			font-weight: 500;
			font-size: 14px;
			line-height: 21px;
			color: #706e6e;
		}
	}

	p {
		font-family: poppins;
		font-weight: 500;
		font-size: 18px;
		line-height: 27px;
		text-align: center;
		max-width: 1195px;
		width: 100%;
		margin-bottom: 42px;

		@media (max-width: 480px) {
			width: 310px;
			font-size: 12px;
			line-height: 18px;
			margin-bottom: 24px;
		}
	}
`;

const PolicyHead: FC<PolicyHeadProps> = ({
	heading = null,
	subHead = null,
	text = null
}) => (
	<HeadContainer>
		{heading && (
			<h1>
				{heading}
				{subHead && <p>{subHead}</p>}
			</h1>
		)}
		{text && <p>{text}</p>}
	</HeadContainer>
);

interface PolicyHeadProps {
	heading?: string;
	subHead?: string;
	text?: string;
}

export default PolicyHead;
