import { all, call } from '@redux-saga/core/effects';
import appSagas from 'redux/App/App.Saga';
import authSagas from 'redux/Auth/Auth.Saga';
import reportsSaga from 'redux/Reports/Reports.Saga';
import tagSagas from 'redux/Tag/Tag.Saga';
import userSagas from 'redux/User/User.Saga';

export default function* rootSaga() {
	yield all([
		call(authSagas),
		call(appSagas),
		call(userSagas),
		call(tagSagas),
		call(reportsSaga)
	]);
}
