import { FC, ReactNode, useEffect, useRef } from 'react';
import ReportDetailHeader from './Header';
import html2canvas from 'html2canvas';

const Page: FC<{
	name: string;
	children: ReactNode;
	setImage: (value: string) => void;
	waitTime?: number;
}> = ({ name, setImage, children, waitTime = 0 }) => {
	const pageRef = useRef<HTMLDivElement>(null);

	const generateImage = async () => {
		await new Promise(resolve => setTimeout(resolve, waitTime * 1000));

		const page = pageRef.current;
		if (!page) return;

		const canvas = await html2canvas(page);

		setImage(canvas.toDataURL('image/png'));
	};

	useEffect(() => {
		generateImage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			ref={pageRef}
			className='max-w-full w-[874px] max-h-full h-[1240px] flex flex-col border p-3 gap-3'>
			<ReportDetailHeader name={name} />

			{children}
		</div>
	);
};

export default Page;
