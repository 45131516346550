import {
	useEffect,
	useRef,
	MouseEvent,
	useReducer,
	FC,
	ChangeEvent,
	useMemo
} from 'react';
import styled from 'styled-components';
import videoReducer, {
	InitialVideoState,
	setMousePosition,
	setVideoBuffer,
	setVideoCanPlay,
	setVideoDuration,
	setVisibility,
	setFullScreen as setFullscreen,
	setVideoCurrentTimePercent,
	setPlayingEnd,
	setPlayingStart,
	setVideoLoading,
	resetVideoState,
	VideoActions
} from './VideoReducer';
import { GrFormClose } from 'react-icons/gr';
import { IoContract, IoExpand, IoPauseCircle } from 'react-icons/io5';
import Spinner from 'components/Spinner/Spinner';
import { PlayBtnIcon } from 'assets/images';

const VideoPlayer: FC<VideoPlayerProps> = ({ url, videoName, courseName }) => {
	videoName = videoName.replace(/exercise(\s)*[-:]/gi, '').trim();
	const videoRef = useRef<HTMLVideoElement>(null),
		wrapperRef = useRef<HTMLDivElement>(null),
		inputRef = useRef<HTMLInputElement>(null),
		[
			{
				visible,
				mousePosition,
				playing,
				bufferPercent,
				currentTimePercent,
				isFullScreen,
				loading
			},
			dispatch
		] = useReducer(videoReducer, InitialVideoState),
		dispatchOnEvent = (action: VideoActions) => () => dispatch(action);

	useEffect(() => {
		const timeout = setTimeout(() => dispatch(setVisibility(false)), 5000);
		return () => clearTimeout(timeout);
	}, [visible, mousePosition]);

	useEffect(() => {
		if (mousePosition.clientX === 0 && mousePosition.clientY === 0) return;
		dispatch(setVisibility(true));
	}, [mousePosition]);

	useEffect(() => {
		if (!videoRef.current) return;
		handleDurationChange();
	}, []);

	useEffect(() => {
		if (!wrapperRef.current) return;

		wrapperRef.current.onfullscreenchange = () =>
			dispatch(setFullscreen(!isFullScreen));
	}, [isFullScreen]);

	useEffect(() => {
		if (bufferPercent < currentTimePercent) dispatch(setVideoLoading(true));
		dispatch(setVideoLoading(false));
	}, [bufferPercent, currentTimePercent]);

	useEffect(() => {
		dispatch(resetVideoState());
		videoRef.current?.load();
	}, [url]);

	const handleMouseMovement = ({
			clientX,
			clientY
		}: MouseEvent<HTMLVideoElement | HTMLDivElement>) =>
			dispatch(setMousePosition({ clientX, clientY })),
		togglePlay = () => {
			if (!videoRef.current) return;
			if (videoRef.current.paused) videoRef.current.play();
			else videoRef.current.pause();
		},
		handleDurationChange = () =>
			dispatch(setVideoDuration(videoRef.current?.duration || 0)),
		handleVideoProgress = () => {
			if (!videoRef.current || !videoRef.current.buffered.length) return;

			const buffered = videoRef.current.buffered.end(0);
			dispatch(setVideoBuffer(buffered));
		},
		handleVideoTimeUpdate = () => {
			if (!videoRef.current) return;
			if (!inputRef.current) return;
			const { currentTime, duration } = videoRef.current;
			const percent = (currentTime / (duration || 100)) * 100;
			dispatch(setVideoCurrentTimePercent(percent));
			inputRef.current.value = percent.toFixed(2);
		},
		handleVideoEnd = () => dispatch(setPlayingEnd()),
		handleVideoStart = () => dispatch(setPlayingStart()),
		handleVideoCanPlay = () => dispatch(setVideoCanPlay(true)),
		setFullScreen = () => wrapperRef.current?.requestFullscreen(),
		exitFullScreen = () => document.exitFullscreen(),
		toggleFullScreen = () =>
			isFullScreen ? exitFullScreen() : setFullScreen(),
		handleSliderChange = ({
			target: { value }
		}: ChangeEvent<HTMLInputElement>) => {
			if (!videoRef.current) return;
			videoRef.current.currentTime = (videoRef.current.duration / 100) * +value;
		},
		handleOnLoadStart = () => dispatch(setVideoLoading(true)),
		handleOnLoadedData = () => dispatch(setVideoLoading(false)),
		visibility = useMemo(() => {
			if (!playing && !visible) return 'flex';
			if (visible) return 'flex';
			return 'hidden';
		}, [visible, playing]);

	return (
		<div className='w-full aspect-video relative flex-center' ref={wrapperRef}>
			<video
				ref={videoRef}
				playsInline
				className='w-full h-full aspect-video relative z-0'
				src={url}
				onMouseEnter={dispatchOnEvent(setVisibility(true))}
				onMouseLeave={dispatchOnEvent(setVisibility(false))}
				onTouchStart={dispatchOnEvent(setVisibility(true))}
				onTouchEnd={dispatchOnEvent(setVisibility(false))}
				onMouseMove={handleMouseMovement}
				onProgress={handleVideoProgress}
				onTimeUpdate={handleVideoTimeUpdate}
				onEnded={handleVideoEnd}
				onCanPlay={handleVideoCanPlay}
				onDurationChange={handleDurationChange}
				onPause={handleVideoEnd}
				onPlay={handleVideoStart}
				onLoadStart={handleOnLoadStart}
				onLoadedData={handleOnLoadedData}
			/>
			<div
				onMouseEnter={dispatchOnEvent(setVisibility(true))}
				onMouseLeave={dispatchOnEvent(setVisibility(false))}
				onMouseMove={handleMouseMovement}
				className={`absolute z-10 w-full h-full bg-black-fade p-[5%_4%_7%] flex-col justify-between items-center ${visibility}`}>
				<div className='text-white flex justify-between w-full relative h-fit'>
					<div className='flex-center gap-3'>
						<div
							className={`bg-white cursor-pointer w-12 h-11 flex-center rounded-xl ${
								isFullScreen ? 'flex' : 'hidden'
							}`}>
							<GrFormClose className='w-7 h-7' onClick={exitFullScreen} />
						</div>
						<div className='flex flex-col'>
							<p className='text-white font-bold text-sm capitalize'>
								{courseName}
							</p>
							<p className='text-white font-medium text-2xs'>
								<span className='text-white'>Exercise: </span>
								<span className='capitalize text-white'>{videoName}</span>
							</p>
						</div>
					</div>
					<span
						aria-describedby='placeholder for favorites icon and functionality'
						className='invisible'
					/>
				</div>
				<div
					className='h-full flex-center w-full cursor-pointer'
					onClick={togglePlay}>
					{loading ? (
						<Spinner />
					) : !playing ? (
						<PlayBtnIcon />
					) : (
						<IoPauseCircle fill='white' className='w-12 h-12 text-white' />
					)}
				</div>
				<div className='flex w-full items-center justify-between'>
					<div className='h-1 w-[91%]'>
						<VideoProgressBar
							className='w-full h-1 z-0 flex items-stretch rounded-md relative outline-none border-none'
							type='range'
							min={0}
							max={100}
							defaultValue={0}
							ref={inputRef}
							onChange={handleSliderChange}
							style={{
								backgroundImage: `linear-gradient(90deg, #73c3d0 ${currentTimePercent}%, #cccccc ${currentTimePercent}%, #cccccc ${bufferPercent}%, #cccccc ${bufferPercent}%, #ffffff 0%)`
							}}
						/>
					</div>
					<div onClick={toggleFullScreen} className='cursor-pointer'>
						{!isFullScreen ? (
							<IoExpand size={28} fill='#ffffff' />
						) : (
							<IoContract size={28} fill='#ffffff' />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const VideoProgressBar = styled.input`
	-webkit-appearance: none;

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		cursor: pointer;
		border-radius: 50%;
		height: 24px;
		width: 24px;
		color: #73c3d0;
		background-color: #73c3d0;
		border-radius: 50%;
		transition: all 0.5s ease-in-out;
		border: 6px solid white;
		z-index: 20;
		position: relative;
	}
`;

export default VideoPlayer;

interface VideoPlayerProps {
	url: string;
	courseName?: string;
	videoName: string;
}
