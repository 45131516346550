import { useCallback } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';

export const useAnalytics = () => {
	const mixpanel = useMixpanel();

	return {
		trackEvent: useCallback(
			(eventName: string, analyticsData = {}) =>
				mixpanel.track(eventName, analyticsData),
			[mixpanel]
		),
		identifyUser: useCallback(
			(phone: number, analyticsData = {}) => {
				mixpanel.identify(phone);
				mixpanel.people.set(analyticsData);

				// eslint-disable-next-line react-hooks/exhaustive-deps
			},
			[mixpanel]
		)
	};
};
