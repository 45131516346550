import React, { FC, useState } from 'react';
import clsx from 'clsx';

const Tooltip: FC<TooltipProps> = ({ children, text, condition = true }) => {
	const [isVisible, setIsVisible] = useState(false);

	const showTooltip = () => setIsVisible(true);
	const hideTooltip = () => setIsVisible(false);

	return (
		<div
			onMouseEnter={showTooltip}
			onMouseLeave={hideTooltip}
			className='relative inline-block z-10'>
			{children}

			<div
				className={clsx(
					'tooltip-arrow absolute -top-[100%] left-1/2 transform -translate-x-1/2 p-2 bg-black text-white rounded text-xs whitespace-nowrap',
					{
						hidden: !isVisible || !condition,
						block: isVisible
					}
				)}>
				{text}
			</div>
		</div>
	);
};

export default Tooltip;

interface TooltipProps {
	children: React.ReactNode;
	text: string;
	condition?: boolean;
}
