import API_CONSTANTS, { API } from 'assets/Data/api-constants';
import {
	loginUserFail,
	loginUserStart,
	loginUserSuccess,
	resetPasswordFail,
	resetPasswordStart,
	resetPasswordSuccess,
	sendLinkToEmailFail,
	sendLinkToEmailStart,
	sendLinkToEmailSuccess
} from './Auth.Slice';
import { AxiosResponse } from 'axios';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { authenticateUser } from 'redux/App/App.Slice';

function getMessage(status?: number, message?: string) {
	switch (status) {
		case 400:
			return message || 'Something went wrong!';

		case 500:
			return 'Server Error, Please try again later.';

		default:
			return 'Something went wrong!';
	}
}

function* loginUser(action: ReturnType<typeof loginUserStart>) {
	let res;
	try {
		res = (yield API.post(
			API_CONSTANTS.LOGIN_USER,
			action.payload
		)) as unknown as AxiosResponse;
		const {
			data
		}: {
			data: {
				validate: boolean;
				user_id: string;
				show_assessment?: boolean;
			};
		} = res;

		if (!data.validate) {
			toast.error('Login Id or Password was incorrect!');
			yield put(loginUserFail());
			return;
		}

		yield put(loginUserSuccess());
		yield put(
			authenticateUser({
				id: data.user_id,
				showAssessment: data.show_assessment || false
			})
		);
	} catch (error: any) {
		toast.error(getMessage(res?.status, 'Login Id or Password was incorrect!'));
		yield put(loginUserFail());
	}
}

function* sendLinkToEmail(action: ReturnType<typeof sendLinkToEmailStart>) {
	try {
		(yield API.post(API_CONSTANTS.SEND_LINK_TO_EMAIL, {
			mail: action.payload
		})) as unknown as AxiosResponse;

		yield put(sendLinkToEmailSuccess());
	} catch (error) {
		yield put(sendLinkToEmailFail());
	}
}

function* resetPassword(action: ReturnType<typeof resetPasswordStart>) {
	try {
		const { data } = (yield API.post(
			API_CONSTANTS.RESET_PASSWORD,
			action.payload
		)) as unknown as AxiosResponse;

		if (!data.reset) {
			yield put(resetPasswordFail());
			return;
		}

		yield put(resetPasswordSuccess());
	} catch (error) {
		yield put(resetPasswordFail());
	}
}

function* watchLoginUser() {
	yield takeLatest(loginUserStart.type, loginUser);
}

function* watchSendLinkToEmail() {
	yield takeLatest(sendLinkToEmailStart.type, sendLinkToEmail);
}

function* watchResetPassword() {
	yield takeLatest(resetPasswordStart.type, resetPassword);
}

export default function* authSagas() {
	yield all([
		call(watchLoginUser),
		call(watchSendLinkToEmail),
		call(watchResetPassword)
	]);
}
