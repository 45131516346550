import { useState, useEffect } from 'react';
import { FaArrowAltCircleUp } from 'react-icons/fa';
import clsx from 'clsx';

const ScrollToTop = () => {
	const [isVisible, setIsVisible] = useState(false);

	// Track scroll position
	useEffect(() => {
		const toggleVisibility = () => {
			if (window.scrollY > 100) setIsVisible(true);
			else setIsVisible(false);
		};

		window.addEventListener('scroll', toggleVisibility);
		return () => window.removeEventListener('scroll', toggleVisibility);
	}, []);

	// Scroll to top logic
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	return (
		<div
			className={clsx(
				'fixed bottom-10 right-10 z-50 cursor-pointer transition-transform duration-500 ease-in-out',
				{
					'translate-x-0 rotate-360': isVisible,
					'translate-x-112 -rotate-360': !isVisible
				}
			)}
			onClick={scrollToTop}>
			<FaArrowAltCircleUp className='w-10 h-10 text-primary' />
		</div>
	);
};

export default ScrollToTop;
