import { FC } from 'react';

const ThemeBtn: FC<ThemeBtnProps> = ({
	handleClick,
	children,
	disabled = false,
	hasFullWidth = false,
	isBlue = false,
	className = ''
}) => (
	<button
		disabled={disabled}
		onClick={handleClick}
		className={`self-center flex items-center justify-center gap-1 rounded-md font-medium text-sm tracking-widest py-3 px-8 shadow-themeShadow transition-500 ${
			hasFullWidth ? 'w-full mx-6' : ''
		} ${isBlue ? 'bg-blue-2 text-white' : 'bg-theme-btn'} ${className}`}>
		{children}
	</button>
);

export default ThemeBtn;

interface ThemeBtnProps {
	handleClick: () => void;
	children: React.ReactNode;
	disabled?: boolean;
	hasFullWidth?: boolean;
	isBlue?: boolean;
	className?: string;
}
