import Router from 'router';
import './App.scss';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRTKDispatch } from 'assets/Hooks/redux-hooks';
import { resizeWindow } from 'redux/App/App.Slice';

function App() {
	const dispatch = useRTKDispatch();

	useEffect(() => {
		globalThis.toast = toast;
		window.onresize = () =>
			dispatch(
				resizeWindow({
					width: window.innerWidth,
					height: window.innerHeight
				})
			);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ToastContainer position={toast.POSITION.TOP_CENTER} />
			<Router />
		</>
	);
}

export default App;
