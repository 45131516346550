import PATH_CONSTANTS from 'assets/Data/path-constants';
import { useRTKDispatch, useRTKSelector } from 'assets/Hooks/redux-hooks';
import { SchoolOnboardingBg } from 'assets/images';
import SchoolFormInput from 'components/SchoolFormInput/SchoolFormInput';
import SchoolLoginLogos from 'components/SchoolLoginLogos/SchoolLoginLogos';
import SchoolLoginPopup from 'components/SchoolLoginPopup/SchoolLoginPopup';
import ThemeBtn from 'components/ThemeBtn/ThemeBtn';
import { resetPasswordStart } from 'redux/Auth/Auth.Slice';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

const SchoolResetPass = () => (
	<SchoolResetPassWrapper className='w-full h-full flex justify-around'>
		<SchoolForm />
		<span />
	</SchoolResetPassWrapper>
);

const SchoolForm = () => {
	const [state, setState] = useState({
			otp: '',
			password: '',
			confirmPassword: '',
			email: ''
		}),
		[popup, setPopup] = useState({
			visible: false,
			type: 'default' as 'success' | 'error' | 'default',
			text: '',
			element: <></>
		});

	const dispatch = useRTKDispatch(),
		resetPassStatus = useRTKSelector(
			state => state.authReducer.passResetStatus
		),
		navigate = useNavigate();

	const { otp, password, confirmPassword, email } = state,
		{ visible, type, text, element } = popup;

	useEffect(() => {
		switch (resetPassStatus) {
			case 'success':
				return changePopupState({
					visible: true,
					type: 'success',
					text: 'Password Updated',
					element: (
						<p
							className='text-primary cursor-pointer'
							onClick={redirectToLogin}>
							Login now
						</p>
					)
				});

			case 'error':
				return changePopupState({
					visible: true,
					type: 'error',
					text: 'Email or OTP is incorrect',
					element: (
						<p>
							Re-check your email address and OTP or Contact wonderLearn for
							support.
							<a href='mailto:support@wonderhood.in'>support@wonderhood.in</a>
						</p>
					)
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resetPassStatus]);

	const handleChange = ({
			target: { value, name }
		}: React.ChangeEvent<HTMLInputElement>) => changeState(name, value),
		changeState = (key: string, value: string | boolean) =>
			setState(prevState => ({
				...prevState,
				[key]: value
			})),
		handleSubmit = () => {
			if (email.length < 12)
				return toast.warn('Please enter a valid email address!');

			if (!email.includes('@') || !email.includes('.'))
				return toast.warn('Please enter a valid email address!');

			if (password.length < 6)
				return toast.warn('Password must be at least 6 characters long!');

			if (password !== confirmPassword)
				return toast.warn('Passwords do not match!');

			dispatch(resetPasswordStart({ mail: email, otp, password }));
		},
		changePopupState = (newState: Partial<typeof popup>) =>
			setPopup(prevState => ({
				...prevState,
				...newState
			})),
		redirectToLogin = () => navigate(PATH_CONSTANTS.DEFAULT),
		togglePopup = () => changePopupState({ visible: !visible });

	return (
		<div className='flex flex-col gap-6 mt-24'>
			<SchoolLoginLogos />
			<div className='flex flex-col gap-8'>
				<div className='flex flex-col gap-6'>
					<SchoolFormInput
						label='Email'
						id='email'
						placeholder='Enter Email Address'
						value={email}
						name='email'
						onChange={handleChange}
					/>
					<SchoolFormInput
						label='OTP'
						id='otp'
						placeholder='Enter OTP'
						value={otp}
						name='otp'
						onChange={handleChange}
					/>
					<SchoolFormInput
						label='New Password'
						id='password'
						placeholder='Enter Password'
						value={password}
						name='password'
						onChange={handleChange}
					/>
					<SchoolFormInput
						label='Re-Enter New Password'
						id='confirm-password'
						placeholder='Re-Enter Password'
						value={confirmPassword}
						name='confirmPassword'
						onChange={handleChange}
					/>
				</div>

				<div className='flex flex-col gap-5'>
					<ThemeBtn hasFullWidth handleClick={handleSubmit}>
						Reset Password
					</ThemeBtn>
					<div className='flex justify-end px-1'>
						<p
							className='cursor-pointer text-primary'
							onClick={redirectToLogin}>
							Login
						</p>
					</div>
				</div>
				<SchoolLoginPopup
					text={text}
					element={element}
					type={type}
					visible={visible}
					handleClose={togglePopup}>
					<></>
				</SchoolLoginPopup>
			</div>
		</div>
	);
};

const SchoolResetPassWrapper = styled.div`
	background-image: url(${SchoolOnboardingBg});
	background-size: cover;
	background-position: top;
	background-repeat: no-repeat;
`;

export default SchoolResetPass;
