import { createAction } from 'redux/Root/Root.Actions';

export const InitialLibrary: InitialLibraryType = {
	courses: [],
	selectedTag: 'set-none',
	expandErrorActive: false
};

export enum LIBRARY_CONSTANTS {
	SET_COURSES = '@library/SET_COURSES',
	TOGGLE_TAG_SELECT = '@library/TOGGLE_TAG_SELECT',
	SET_EXPAND_ERROR = '@library/SET_EXPAND_ERROR	'
}

const libraryReducer = (
	state = InitialLibrary,
	action: LibraryActions
): InitialLibraryType => {
	switch (action.type) {
		case LIBRARY_CONSTANTS.SET_COURSES:
			return { ...state, courses: action.payload };

		case LIBRARY_CONSTANTS.TOGGLE_TAG_SELECT:
			return { ...state, selectedTag: action.payload };

		case LIBRARY_CONSTANTS.SET_EXPAND_ERROR:
			return { ...state, expandErrorActive: action.payload };

		default:
			return state;
	}
};

interface InitialLibraryType {
	courses: Content[];
	selectedTag: LibraryTags;
	expandErrorActive: boolean;
}

export default libraryReducer;

export type LibraryActions =
	| ReturnType<typeof setCourses>
	| ReturnType<typeof toggleTagSelect>
	| ReturnType<typeof setExpandError>;

export const setCourses = (courses: Content[]) =>
	createAction(LIBRARY_CONSTANTS.SET_COURSES, courses);

export const toggleTagSelect = (tag: LibraryTags) =>
	createAction(LIBRARY_CONSTANTS.TOGGLE_TAG_SELECT, tag);

export const setExpandError = (payload: boolean) =>
	createAction(LIBRARY_CONSTANTS.SET_EXPAND_ERROR, payload);
