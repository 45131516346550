import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoArrowBackOutline } from 'react-icons/io5';
import PATH_CONSTANTS from 'assets/Data/path-constants';

const BackButton: FC<{ to?: PATH_CONSTANTS; className?: string }> = ({
	to,
	className = ''
}) => {
	const navigate = useNavigate(),
		navigateTo = () => {
			if (to) return navigate(to);

			navigate(-1);
		};

	return (
		<IoArrowBackOutline
			className={`pl-2 cursor-pointer w-6 md:w-8 h-6 md:h-8 ${className}`}
			onClick={navigateTo}
		/>
	);
};

export default BackButton;
