import { FC } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import Select from 'react-select';

const Pagination: FC<PaginationProps> = ({
	page,
	totalPages,
	setPage,
	children
}) => {
	console.log(page, totalPages, 'page, totalPages');

	const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

	return (
		<div className='w-full flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6'>
			<div className='hidden sm:flex sm:flex-1 sm:items-center sm:justify-between'>
				<div>{children}</div>

				<nav
					className='isolate inline-flex -space-x-px rounded-md shadow-sm'
					aria-label='Pagination'>
					<button
						disabled={page === 1}
						onClick={setPage.bind(null, page - 1)}
						className='relative inline-flex items-center rounded-l-md px-2 py-2 disabled:text-gray-400 text-gray-900 disabled:cursor-not-allowed cursor-pointer ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'>
						<span className='sr-only'>Previous</span>
						<BiChevronLeft className='h-5 w-5' aria-hidden='true' />
					</button>
					<Select
						className='relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0'
						options={pages.map(page => ({
							value: page,
							label: page.toString()
						}))}
						styles={{
							control: provided => ({
								...provided,
								border: 'none',
								outline: 'none',
								boxShadow: 'none'
							})
						}}
						value={{ value: page, label: page.toString() }}
						onChange={page => setPage(page?.value || 0)}
					/>

					<button
						disabled={page === totalPages}
						onClick={setPage.bind(null, page + 1)}
						className='relative inline-flex items-center rounded-r-md px-2 py-2 disabled:text-gray-400 text-gray-900 disabled:cursor-not-allowed cursor-pointer ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'>
						<span className='sr-only'>Next</span>
						<BiChevronRight className='h-5 w-5' aria-hidden='true' />
					</button>
				</nav>
			</div>
		</div>
	);
};

export default Pagination;

export interface PaginationProps {
	page: number;
	totalPages: number;
	setPage: (page: number) => void;
	children?: React.ReactNode;
}
