import { Bulb } from 'assets/images';
import { FC } from 'react';

const Skills: FC<SkillsProps> = ({ heading, skills, color }) => (
	<div
		className={`rounded-lg flex flex-col py-5 px-8 gap-8 w-full h-full ${color}`}>
		<p className='text-center text-2xl'>{heading}</p>
		<img src={Bulb} alt='' className='absolute z-10 w-20 h-20 mix-blend-multiply' />
		<div className='w-full h-full flex flex-col gap-5'>
			{skills.map(({ header, assessment }, i) => (
				<div key={header + 'skill' + i}>
					<p className='text-center text-2xl'>{header}</p>
					<ul className='w-full h-full flex flex-col gap-5'>
						{assessment.map(point => (
							<li className='bullet list-none text-lg'>{point}</li>
						))}
					</ul>
				</div>
			))}
		</div>
	</div>
);

interface SkillsProps {
	heading: string;
	skills: {
		header: string;
		assessment: string[];
	}[];
	color: string;
}

export default Skills;
