import ScrollSnapPlugin from './ScrollSnapPlugin';
import type ScrollSnapSlider from './ScrollSnapSlider';

export default class ScrollSnapDraggable extends ScrollSnapPlugin {
	startX: number;
	lastX: number;
	quickSwipeDistance: number;
	disableTimeout: number;
	slider?: ScrollSnapSlider;
	element?: HTMLElement;

	constructor(quickSwipeDistance: number = 0) {
		super();

		this.lastX = 0;
		this.startX = 0;
		this.quickSwipeDistance = quickSwipeDistance;
		this.disableTimeout = 0;

		this.mouseMove = this.mouseMove.bind(this);
		this.startDragging = this.startDragging.bind(this);
		this.stopDragging = this.stopDragging.bind(this);
	}

	enable(slider: ScrollSnapSlider) {
		this.slider = slider;
		this.element = this.slider.element;

		this.element.classList.add('-draggable');

		this.slider.addEventListener('mousedown', this.startDragging);
		window.addEventListener('mouseup', this.stopDragging, { capture: true });
	}

	disable() {
		this.element?.classList.remove('-draggable');

		this.disableTimeout && clearTimeout(this.disableTimeout);
		this.slider?.removeEventListener('mousedown', this.startDragging);
		window.removeEventListener('mouseup', this.stopDragging, { capture: true });

		this.slider = undefined;
		this.element = undefined;
		this.disableTimeout = 0;
		this.lastX = 0;
	}

	mouseMove(event: MouseEvent) {
		const distance = this.lastX - event.clientX;
		this.lastX = event.clientX;

		if (this.element) this.element.scrollLeft += distance;
	}

	startDragging(event: MouseEvent) {
		event.preventDefault();
		clearTimeout(this.disableTimeout);

		this.startX = this.lastX = event.clientX;
		if (this.element) {
			this.element.style.scrollBehavior = 'auto';
			this.element.style.scrollSnapStop = 'unset';
			this.element.style.scrollSnapType = 'none';
			this.element.classList.add('grabbing');
		}

		const autoplay = this.slider?.plugins.get('ScrollSnapAutoplay');
		if (autoplay) autoplay.disable();

		window.addEventListener('mousemove', this.mouseMove);
	}

	stopDragging(event: MouseEvent) {
		if (this.lastX === null) {
			return;
		}

		event.preventDefault();

		const finalSlide = this.getFinalSlide();

		window.removeEventListener('mousemove', this.mouseMove);
		this.lastX = 0;
		if (this.element) {
			this.element.style.scrollBehavior = '';
			this.element.classList.remove('grabbing');
		}

		this.slider?.slideTo(finalSlide || 0);

		const autoplay = this.slider?.plugins.get('ScrollSnapAutoplay');
		if (autoplay && this.slider) autoplay.enable(this.slider);

		this.disableTimeout = setTimeout(() => {
			if (this.element) {
				this.element.style.scrollSnapStop = '';
				this.element.style.scrollSnapType = '';
			}
		}, 300) as unknown as number;
	}

	getFinalSlide() {
		if (!this.quickSwipeDistance) return this.slider?.slide;

		const distance = Math.abs(this.startX - this.lastX);
		const minimumNotReached = this.quickSwipeDistance > distance;
		if (!this.element || !this.slider) return;
		const halfPointCrossed = distance > this.element.offsetWidth / 2;

		if (minimumNotReached || halfPointCrossed) return this.slider?.slide;

		if (this.startX < this.lastX) return this.slider.slide - 1;

		return this.slider.slide + 1;
	}
}
