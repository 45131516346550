import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import authReducer from '../Auth/Auth.Slice';
import appReducer from 'redux/App/App.Slice';
import userReducer from 'redux/User/User.Slice';
import tagReducer from 'redux/Tag/Tag.Slice';
import reportsReducer from 'redux/Reports/Reports.Slice';

const ENV = process.env.NODE_ENV;

const rootPersistConfig = {
	key: 'LMS-root-' + ENV,
	storage,
	whitelist: ['userReducer']
};

const authPersistConfig = {
	key: 'LMS-auth-' + ENV,
	storage: storageSession
};

const appPersistConfig = {
	key: 'LMS-app-' + ENV,
	storage: storageSession
};

const tagPersistConfig = {
	key: 'LMS-tag-' + ENV,
	storage: storageSession
};

const reportsPersistConfig = {
	key: 'LMS-reports-' + ENV,
	storage: storageSession
};

const rootReducer = combineReducers({
	authReducer: persistReducer(authPersistConfig, authReducer),
	tagReducer: persistReducer(tagPersistConfig, tagReducer),
	appReducer: persistReducer(appPersistConfig, appReducer),
	reportsReducer: persistReducer(reportsPersistConfig, reportsReducer),
	userReducer
});

export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export default rootReducer;
