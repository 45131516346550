import PATH_CONSTANTS from 'assets/Data/path-constants';
import { useRTKSelector } from 'assets/Hooks/redux-hooks';
import Navbar from 'components/Navbar/Navbar';
import ParentalPopUp from 'components/ParentalPopUp/ParentalPopUp';
import CoursePlayer from 'pages/CoursePlayer/CoursePlayer';
import Dashboard from 'pages/Dashboard/Dashboard';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import Library from 'pages/Library/Library';
import ReportDetail from 'pages/Reports/ReportDetail';
import ReportsLayout from 'pages/Reports/ReportsLayout';
import Reports from 'pages/Reports';
import { Route, Routes, Outlet } from 'react-router-dom';

const AuthenticatedRoutes = () => {
	const showAssessment = useRTKSelector(
		state => state.userReducer.showAssessment
	);

	return (
		<Routes key='authenticated-routes'>
			<Route path={PATH_CONSTANTS.DEFAULT} element={<DefaultLayout />}>
				<Route index element={<Dashboard />} />
				<Route path={PATH_CONSTANTS.LIBRARY} element={<Library />} />
				<Route path={PATH_CONSTANTS.COURSE_PLAYER} element={<CoursePlayer />} />
				{showAssessment && (
					<Route path={PATH_CONSTANTS.REPORTS} element={<ReportsLayout />}>
						<Route index element={<Reports />} />
						<Route
							path={PATH_CONSTANTS.REPORT_DETAIL}
							element={<ReportDetail />}
						/>
					</Route>
				)}
				<Route path={PATH_CONSTANTS.ALL} element={<ErrorPage />} />
			</Route>
		</Routes>
	);
};


const DefaultLayout = () => {
	const parentalCallback = useRTKSelector(
		state => state.appReducer.parentalCallback
	);

	return (
		<div className='flex flex-col w-full h-full'>
			{!!parentalCallback && <ParentalPopUp />}
			<Navbar />
			<Outlet />
		</div>
	);
};

export default AuthenticatedRoutes;
