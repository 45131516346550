import API_CONSTANTS, { API } from 'assets/Data/api-constants';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import {
	fetchAssessmentFailure,
	fetchAssessmentStart,
	fetchAssessmentSuccess,
	fetchStudentsFailure,
	fetchStudentsStart,
	fetchStudentsSuccess,
	saveAssessmentFailure,
	saveAssessmentStart,
	saveAssessmentSuccess
} from './Reports.Slice';
import { AxiosResponse } from 'axios';

function* fetchAssessment(action: ReturnType<typeof fetchAssessmentStart>) {
	try {
		const { data } = (yield API.post(
			action.payload.shareWithParent
				? API_CONSTANTS.SAVE_ASSESSMENT
				: API_CONSTANTS.ASSESSMENT,
			action.payload,
			{
				headers: {
					user_id: action.payload.user_id
				}
			}
		)) as AxiosResponse<AssessmentResponse>;

		if (action.payload.shareWithParent) {
			yield put(saveAssessmentSuccess());
			return;
		}

		yield put(fetchAssessmentSuccess(data));
	} catch (error: any) {
		if (action.payload.shareWithParent) {
			yield put(saveAssessmentFailure(error.message));
			return;
		}

		yield put(fetchAssessmentFailure(error.message));
	}
}

function* fetchStudentsList(action: ReturnType<typeof fetchStudentsStart>) {
	try {
		const { data } = (yield API.get(API_CONSTANTS.USERS_LIST, {
			params: {
				page_num: action.payload.page,
				page_size: action.payload.limit,
				grade: action.payload.grade
			},
			headers: {
				user_id: action.payload.userId
			}
		})) as AxiosResponse<StudentsList>;

		data.usersList.sort((a, b) => a.childName.localeCompare(b.childName));

		yield put(
			fetchStudentsSuccess({
				...data,
				usersList: data.usersList
			})
		);
	} catch (error: any) {
		yield put(fetchStudentsFailure(error.message));
	}
}

function* watchSaveAssessment() {
	yield takeLatest(saveAssessmentStart.type, fetchAssessment);
}

function* watchFetchStudentsList() {
	yield takeLatest(fetchStudentsStart.type, fetchStudentsList);
}

function* watchFetchAssessment() {
	yield takeLatest(fetchAssessmentStart.type, fetchAssessment);
}

export default function* reportsSaga() {
	yield all([
		call(watchFetchAssessment),
		call(watchFetchStudentsList),
		call(watchSaveAssessment)
	]);
}
