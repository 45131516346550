import { FC, ReactNode } from 'react';
import UnorderedListContainer from './UnorderedList.styled';

const UnorderedList: FC<UnorderedListProps> = ({
	heading = null,
	children = []
}) => {
	!Array.isArray(children) && (children = [children]);

	return (
		<UnorderedListContainer>
			{heading && <h1>{heading}</h1>}
			<ul>
				{Array.isArray(children) &&
					children.map((item, index) => (
						<li key={`${item}-${index}`}>{item}</li>
					))}
			</ul>
		</UnorderedListContainer>
	);
};

interface UnorderedListProps {
	heading?: string;
	children?: ReactNode[] | ReactNode;
}

export default UnorderedList;
