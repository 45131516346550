import Axios from 'axios';

export const baseURL = 'https://d154q3dh2dqk2h.cloudfront.net/';

export const API = Axios.create({
	baseURL,
	headers: {
		'Content-Type': 'application/json',
	}
});

enum API_CONSTANTS {
	DEFAULT = 'https://d154q3dh2dqk2h.cloudfront.net/',
	ZOHOLEAD = '/zoholead',
	UPGRADE_CONFIRMATION = '/upgrade-confirmation',
	CHECK_PHONE_SNS = '/check_phone_sns',
	LOGIN_USER = '/school/user/login',
	VERIFY_USER = '/otp/validate',
	REGISTER_USER = '/user/register',
	NOTIFICATION_CONTENT = '/notification_content',
	PROBLEM_TAGS_FROM_COURSE = '/v1/lms/problem-tags-from-course',
	CLIENT_INFO = '/client-info',
	REGISTER_CHILD = '/register-child',
	COMMUNITY_PAGE = '/community_page',
	FEEDS = 'v1/lms/feeds',
	SEARCH_BY_TAGS = '/v1/lms/searchbytags',
	UPDATE_PARENT = '/update-parent',
	UPDATE_CHILD = '/update-child',
	GET_PROFILE = '/get-profile',
	FEATURED = 'v1/lms/featured',
	REFERRAL = '/referral',
	SEND_OTP = '/otp/send',
	VALIDATE_OTP = '/otp/validate',
	ACTIVATE_TOY = '/activate/toy',
	SEND_LINK_TO_EMAIL = '/forget/otp/send',
	RESET_PASSWORD = '/forget/otp/validate',
	ASSESSMENT = '/v2/assessment',
	SAVE_ASSESSMENT = '/v2/assessment/save',
	USERS_LIST = '/v1/school/users'
}

export default API_CONSTANTS;
