import { FC } from 'react';
import { Doughnut } from 'react-chartjs-2';

const ProgressChart: FC<{
	labels: string[];
	data: number[];
	colors: string[];
}> = ({ labels, data, colors }) => (
	<div className='w-full h-full aspect-square flex justify-center items-center'>
		<Doughnut
			data={{
				labels,
				datasets: [
					{
						label: 'Course Progress',
						data: data,
						backgroundColor: colors,
						hoverOffset: 4
					}
				]
			}}
		/>
	</div>
);

export default ProgressChart;
