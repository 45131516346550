import { takeLatest, call, put, all } from 'redux-saga/effects';

import { authenticateUser, logoutUser } from './App.Slice';
import { logoutAuthUser } from 'redux/Auth/Auth.Slice';
import {
	authenticationSuccess,
	fetchProfileStart,
	logoutActualUser
} from 'redux/User/User.Slice';
import { fetchAppFeedStart, logoutTagUser } from 'redux/Tag/Tag.Slice';

function* logoutUserSaga() {
	yield put(logoutAuthUser());
	yield put(logoutActualUser());
	yield put(logoutTagUser());
}

function* authenticateUserSaga(action: ReturnType<typeof authenticateUser>) {
	yield put(authenticationSuccess());
	yield put(fetchProfileStart(action.payload));
	yield put(fetchAppFeedStart());
}

function* watchLogoutUser() {
	yield takeLatest(logoutUser.type, logoutUserSaga);
}

function* watchAuthenticateUser() {
	yield takeLatest(authenticateUser.type, authenticateUserSaga);
}

export default function* appSagas() {
	yield all([call(watchLogoutUser), call(watchAuthenticateUser)]);
}
