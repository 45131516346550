import PATH_CONSTANTS from 'assets/Data/path-constants';
import { useRTKDispatch, useRTKSelector } from 'assets/Hooks/redux-hooks';
import { SchoolOnboardingBg } from 'assets/images';
import SchoolFormInput from 'components/SchoolFormInput/SchoolFormInput';
import SchoolLoginLogos from 'components/SchoolLoginLogos/SchoolLoginLogos';
import SchoolLoginPopup from 'components/SchoolLoginPopup/SchoolLoginPopup';
import ThemeBtn from 'components/ThemeBtn/ThemeBtn';
import {
	loginUserStart,
	sendLinkToEmailStart,
	setInitialEmailStatus
} from 'redux/Auth/Auth.Slice';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SchoolLogin = () => (
	<SchoolLoginWrapper className='w-full h-full flex justify-around'>
		<SchoolForm />
		<span />
	</SchoolLoginWrapper>
);

const SchoolForm = () => {
	const [state, setState] = useState({
			loginId: '',
			password: '',
			checked: false
		}),
		[popup, setPopup] = useState({
			visible: false,
			type: 'default' as 'success' | 'error' | 'default',
			text: '',
			element: <></>
		});

	const dispatch = useRTKDispatch(),
		sendLinkToEmailStatus = useRTKSelector(
			state => state.authReducer.emailSentStatus
		);

	const { visible, type, text, element } = popup,
		{ loginId, password } = state;

	useEffect(() => {
		switch (sendLinkToEmailStatus) {
			case 'success':
				return changePopupState({
					type: 'success',
					text: 'Email Sent',
					element: (
						<p>
							<span>Check your email we sent and</span>{' '}
							<Link to={PATH_CONSTANTS.RESET_PASSWORD}>click here </Link>
							<span>to continue</span>
						</p>
					)
				});

			case 'error':
				return changePopupState({
					type: 'error',
					text: 'Email Not found!',
					element: (
						<p>
							Re-check your email address or Contact wonderLearn for support.
							<a href='mailto:support@wonderhood.in'>support@wonderhood.in</a>
						</p>
					)
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sendLinkToEmailStatus]);

	const handleChange = ({
			target: { value, name }
		}: React.ChangeEvent<HTMLInputElement>) => changeState(name, value),
		changeState = (key: string, value: string | boolean) =>
			setState(prevState => ({
				...prevState,
				[key]: value
			})),
		changePopupState = (newState: Partial<typeof popup>) =>
			setPopup(prevState => ({
				...prevState,
				...newState
			})),
		// toggleCheckbox = () => changeState('checked', !checked),
		handleSubmit = () => dispatch(loginUserStart({ mail: loginId, password })),
		togglePopup = () => changePopupState({ visible: !visible });

	return (
		<div className='flex flex-col gap-6 mt-24'>
			<SchoolLoginLogos />
			<div className='flex flex-col gap-8'>
				<div className='flex flex-col gap-6'>
					<SchoolFormInput
						label='Login ID'
						id='email'
						placeholder='Enter Login ID'
						value={loginId}
						name='loginId'
						onChange={handleChange}
					/>
					<SchoolFormInput
						label='Password'
						id='password'
						placeholder='Enter Password'
						value={password}
						name='password'
						onChange={handleChange}
					/>
				</div>
				<div className='flex flex-col gap-5'>
					<ThemeBtn hasFullWidth handleClick={handleSubmit}>
						Log in
					</ThemeBtn>
					<div className='flex justify-end px-1'>
						{/* <div
							onClick={toggleCheckbox}
							className='flex-center gap-3 cursor-pointer'>
							{checked ? (
								<input
									onClick={toggleCheckbox}
									defaultChecked
									type='checkbox'
									className='accent-primary-24 w-3 h-3 cursor-pointer'
								/>
							) : (
								<div onClick={toggleCheckbox} className='border w-3 h-3' />
							)}
							<label className='cursor-pointer'>Remember me</label>
						</div> */}
						<p
							className='cursor-pointer'
							onClick={() => {
								togglePopup();
								dispatch(setInitialEmailStatus());
							}}>
							Forgot Password?
						</p>
					</div>
				</div>
			</div>
			<SchoolLoginPopup
				text={text}
				element={element}
				type={type}
				visible={visible}
				handleClose={togglePopup}>
				<SendEmail />
			</SchoolLoginPopup>
		</div>
	);
};

const SendEmail = () => {
	const [email, setEmail] = useState(''),
		dispatch = useRTKDispatch();

	const handleChange = ({
			target: { value }
		}: React.ChangeEvent<HTMLInputElement>) => setEmail(value),
		handleClick = () => {
			if (email.length < 12)
				return toast.warn('Please enter a valid email address!');

			if (!email.includes('@') || !email.includes('.'))
				return toast.warn('Please enter a valid email address!');

			dispatch(sendLinkToEmailStart(email));
		};

	return (
		<div className='mt-13 mx-14 flex flex-col'>
			<p className='text-primary font-medium text-2xl'>Forgot Password?</p>
			<p className='mt-6 text-base text-black-9 font-normal'>
				Enter your email address, and we’ll send you a otp to reset your
				password.
			</p>
			<input
				value={email}
				onChange={handleChange}
				type='email'
				id='email'
				name='email'
				placeholder='Enter email address'
				className='mt-4 w-full pl-4 border-none outline-none text-xs placeholder-gray-18 bg-primary-24 h-12 rounded-xl'
			/>
			<ThemeBtn handleClick={handleClick} hasFullWidth className='mt-5'>
				Send OTP to Email
			</ThemeBtn>
		</div>
	);
};

const SchoolLoginWrapper = styled.div`
	background-image: url(${SchoolOnboardingBg});
	background-size: cover;
	background-position: top;
	background-repeat: no-repeat;
`;

export default SchoolLogin;
