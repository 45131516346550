import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useRTKDispatch, useRTKSelector } from 'assets/Hooks/redux-hooks';
import { selectChild } from 'redux/App/App.Slice';
import {
	fetchAppFeedStart,
	fetchFeaturedCoursesStart
} from 'redux/Tag/Tag.Slice';
import Spinner from 'components/Spinner/Spinner';
import { BunnyBg, BunnyBgDesktop, DoodleBgTab } from 'assets/images';
import TagSearch from 'components/TagSearch/TagSearch';
import Slider from 'components/Slider/Slider';
import { useAnalytics } from 'assets/Data/useAnalytics';

const Dashboard: FC = () => {
	const [selectedChild, children, appFeed, coursesRes, width]: [
			string | null,
			Children | null,
			AppFeedRes | null,
			FeaturedCourses,
			number
		] = useRTKSelector(state => [
			state.userReducer.selectedChild,
			state.userReducer.childrenData,
			state.tagReducer.appFeed.data,
			state.tagReducer.featured,
			state.appReducer.window.width
		]),
		dispatch = useRTKDispatch(),
		[child, setChild] = useState<Child | null>(null),
		[feed, setFeed] = useState<Feed[]>([]);

	useEffect(() => {
		if (!children || !children.length) return;
		if (!selectedChild) {
			dispatch(selectChild(children[0]._id.$oid));
			setChild(children[0]);
			return;
		}

		const child = children.find(child => child._id.$oid === selectedChild);
		if (!child) return;
		setChild(child);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [children, selectedChild]);

	useEffect(() => {
		if (!child) return;

		dispatch(fetchFeaturedCoursesStart(child.grade as Grade));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [child]);

	useEffect(() => {
		if (!appFeed) {
			dispatch(fetchAppFeedStart());
			return;
		}

		setFeed(appFeed.feed);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appFeed]);

	return (
		<HomeWrapper className='flex pt-3 flex-col items-center justify-center gap-6 lg:gap-12 pb-10 lg:pb-50'>
			<div className='p-2 lg:p-6'>
				<HomeCarousel {...coursesRes} />
			</div>
			<TagSearch />
			{feed
				.filter((_, i) => i < 2)
				.map(({ header, content }, i) => (
					<Slider
						index={i}
						key={header + i}
						heading={header}
						data={content.map(
							({ thumbnail, progress, slug, id, course_name }) => ({
								thumbnail,
								progress,
								url: `/course-player?course-slug=${slug}`,
								id,
								name: course_name,
								slug
							})
						)}
					/>
				))}
			<img
				src={width > 768 ? BunnyBgDesktop : BunnyBg}
				alt=''
				className='object-contain'
			/>
			{feed
				.filter((_, i) => i > 2)
				.map(({ header, content }, i) => (
					<Slider
						index={i + 2}
						key={header + i}
						heading={header}
						data={content.map(
							({ thumbnail, progress, slug, id, course_name }) => ({
								thumbnail,
								progress,
								url: `/course-player?course-slug=${slug}`,
								id,
								name: course_name,
								slug
							})
						)}
					/>
				))}
		</HomeWrapper>
	);
};

const HomeWrapper = styled.div`
	background-image: url(${DoodleBgTab});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
`;

const HomeCarousel: FC<FeaturedCourses> = ({ courses, error }) => {
	const [selectedIndex, setIndex] = useState(0),
		[stopLoop, setStopLoop] = useState(false),
		navigate = useNavigate();

	const { trackEvent } = useAnalytics();

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (stopLoop) return;
			const nextIndex = selectedIndex + 1;

			if (nextIndex >= (courses?.length || 0)) return setIndex(0);

			setIndex(nextIndex);
		}, 3000);

		return clearTimeout.bind(null, timeout);
	}, [courses?.length, selectedIndex, stopLoop]);

	const handleCourse = () => {
		if (!courses?.length) return;
		const course = courses[selectedIndex];
		if (!course) return;

		trackEvent('Course Opened', {
			course_slug: course.slug,
			course_name: course.name
		});

		navigate(`/course-player?course-slug=${course.slug}`);
	};

	if ((!courses && !error) || error)
		return (
			<div className='w-screen flex justify-center p-3'>
				{error ? <div>{error}</div> : <Spinner />}
			</div>
		);

	return (
		<div
			onMouseEnter={setStopLoop.bind(null, true)}
			onMouseLeave={setStopLoop.bind(null, false)}
			className='flex overflow-hidden w-full rounded-xl'>
			{courses?.map((course: Course, index: number) => (
				<CarouselWrapper
					onClick={handleCourse}
					axis={(index - selectedIndex) * 100}
					className='w-full flex justify-center transition-500 -mr-[100%] relative'
					key={course.course_id}>
					<img src={course.thumbnail} className='object-contain' alt='' />
				</CarouselWrapper>
			))}
		</div>
	);
};

const CarouselWrapper = styled.div<{ axis: number }>`
	transform: translateX(${({ axis }) => axis}%);
`;

export default Dashboard;

interface FeaturedCourses {
	courses: null | Courses;
	error: null | string;
}
